import React, {useEffect, useState} from 'react';
import {getAll} from "../actions/CustomerActions";
import {useDispatch} from "react-redux";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    formControl: {
        width: "100%"
    },
    imageContainer: {
        height: 16,
        width: 16,
        marginRight: 10,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%'
    },
    row: {
        display: 'flex'
    }
});
const CustomerSelect = ({value, onChange}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    useEffect(() => {
        dispatch(getAll(setData));
    }, [dispatch]);
    return (
        <FormControl className={classes.formControl} required>
            <InputLabel id="customer-select-label">Customer</InputLabel>
            <Select
                labelId="customer-select-label"
                id="customer-select"
                value={value || ''}
                onChange={e => onChange(e.target.value)}
            >
                {data.map((e => (<MenuItem key={e.id} value={e.id}>
                    <div className={classes.row}>
                        {e.imageUrl &&
                        <div className={classes.imageContainer}>
                            <img alt="img" className={classes.image} src={e.imageUrl}/>
                        </div>
                        }
                        <span>
                        {e.code}
                        </span>
                    </div>
                </MenuItem>)))}
            </Select>
        </FormControl>
    )
};

export default CustomerSelect;
