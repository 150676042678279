import * as React from 'react';

const FileUpload = ({onChange}) => {


    const handleFileSelect = (event) => {
        // const file = event.target.files[0]
        // const size = file.size // it's in bytes
        // console.log(size)
        // //added a max file size limit of 4000Kb
        // if (size / 1024 > 4000) {
        //     alert("file size must not be greater than to 100Kb")
        //     return
        // }

        //----Image convert to base 64
        const reader = new FileReader()
        reader.onload = () => {
            const binaryStr = reader.result;

            const base64String = btoa(String.fromCharCode(...new Uint8Array(binaryStr)));
            onChange('<img '+base64String);
        }
        reader.readAsArrayBuffer(event.target.files[0])
    }

    return (
        <>
            <input type="file" accept="image/png, image/jpeg" onChange={handleFileSelect}/>
        </>
    );

}
export default FileUpload;