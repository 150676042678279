import React from "react";
import {useSelector} from "react-redux";
import parse from 'html-react-parser'
import {
    FILE_UPLOAD,
    MULTIPLE_CHOICE,
    PREFERENCE_PRIORITY,
    SCALE_TYPE,
    STAR_RATING,
    TEXT_AREA,
    YES_NO,
    YES_NO_COMMENT
} from "./AnswerTypes/AnswerTypes";
import {TextAreaType} from "./AnswerTypes/TextAreaType";
import {MultipleChoice} from "./AnswerTypes/MultipleChoice";
import StarRatingType from "./AnswerTypes/StarRatingType";
import ScaleType from "./AnswerTypes/ScaleType";
import PreferencePriority from "./AnswerTypes/PreferencePriority";
import {YesNo} from "./AnswerTypes/YesNo";
import FileUpload from "./AnswerTypes/FileUpload";
import { YesNoComment } from "./AnswerTypes/YesNoComment";

//----Answer Type Generate
function generateComponent(questionType, options, onChange, questionNo) {
    switch (questionType) {
        case TEXT_AREA:
            return <TextAreaType/>;
        case MULTIPLE_CHOICE:
            return <MultipleChoice updateOptions={null} isPreview={true} questionOptions={options} questionId={questionNo}/>;
        case STAR_RATING:
            return <StarRatingType component={questionType} onChange={onChange}/>;
        case SCALE_TYPE:
            return <ScaleType/>;
        case PREFERENCE_PRIORITY:
            return <PreferencePriority updateOptions={null} isPreview={true} questionOptions={options} questionId={questionNo}/>;
        case YES_NO:
            return <YesNo onChange={onChange}/>;
        case YES_NO_COMMENT:
            return <YesNoComment onChange={onChange}/>
        case FILE_UPLOAD:
            return <FileUpload onChange={onChange}/>;
        default:
            return <TextAreaType/>;
    }
}

const Preview = () => {
    const survey = useSelector(state => state.survey);

    return (
        <div className="pre-container container">
            {survey.isEdit ?
                <div className="card">
                    <div className="card-header">
                        <div className="left-logo-view">
                            {/*{survey.leftLogo ? <img alt="lftImage" className="lft-img" src={`data:image/jpeg;base64,${survey.leftLogo}`}/> : ''}*/}
                            <img alt="Logo" src="/images/complexica-logo.png" height="8px"/>
                        </div>


                        <div><h4>{parse(survey.surveyTitle ? survey.surveyTitle : '')}</h4></div>

                        <div className="right-logo-view">
                            {/*{survey.rightLogo ? <img alt="rightImage" className="rgt-img" src={`data:image/jpeg;base64,${survey.rightLogo}`}/> : ''}*/}
                        </div>
                    </div>

                    <div className="s-description">{parse(survey.surveyDescription ? survey.surveyDescription : '')}</div>

                    {survey.questions.map((question, index) => (
                        <div className="card-body" key={index}>
                            <h5 className="card-title">{index + 1}. {parse(question.title)}</h5>
                            <div className="card-text">
                                {question.type === MULTIPLE_CHOICE || question.type === PREFERENCE_PRIORITY ?
                                    generateComponent(question.type, question.answers, ()=>{}, index + 1) :
                                    generateComponent(question.type, question,()=>{}, index + 1) }

                            </div>

                        </div>
                    ))}

                    <div className="done-btn-div">
                        <button type="button" className="btn btn-primary">Submit Responses</button>
                    </div>

                </div>

                : <div className='preview-txt'>No preview to show</div>
            }


        </div>

    );

}

export default Preview;
