import {Button, Paper} from "@material-ui/core";
import ProductSurveyTable from "../product/survey/ProductSurveyTable";
import ScheduleSurveyDialog from "../product/survey/ScheduleSurveyDialog";
import React, {useState} from "react";
import {useQuery} from "../../hooks/useQuery";
import {QueuePlayNext} from "@material-ui/icons";


const RespondentSurveys = () => {
    const [reload, setReload] = useState(1);
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const query = useQuery();
    const groupId = query.get("groupId");
    const [pSurvey, setPSurvey] = useState(null);
    const openModal = (productSurvey) => {
        setOpenScheduleDialog(true);
        setPSurvey(productSurvey);
    }


    return(
        <Paper style={{padding: 10}}>
            <Button color="primary"
                    onClick={() => setOpenScheduleDialog(true)}
                    startIcon={<QueuePlayNext/>}> Schedule new survey for this group</Button>

            <ProductSurveyTable productId={groupId} reload={reload} openModal={openModal} />

            <ScheduleSurveyDialog open={openScheduleDialog} groupId={groupId} productSurvey={pSurvey} handleClose={() => {
                setOpenScheduleDialog(false);
                setReload(reload + 1)
            }}/>

            {/*<AssignSurvey open={openScheduleDialog} productId={groupId} handleClose={() => {*/}
            {/*    setOpenScheduleDialog(false);*/}
            {/*    setReload(reload + 1)*/}
            {/*}}/>*/}
        </Paper>
    )
}
export default RespondentSurveys;