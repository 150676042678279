import {Button, Card, CardActions, CardContent, Popover, TextField} from "@material-ui/core";
import React, {useState} from "react";
import {newUserGroups} from "../../../actions/ProductActions";
import {useDispatch} from "react-redux";
import {GroupAdd} from '@material-ui/icons';

const AddGroupButton = ({productId, handleNew}) => {
    const [newValue, setNewValue] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch();

    const handleSave = () => {
        dispatch(newUserGroups({name: newValue, productId}, (newGroup) => {
            handleClose();
            handleNew(newGroup)
        }));
    };

    return (<React.Fragment>
        <Button color="primary"
                onClick={handleClick}
                startIcon={<GroupAdd/>}> Create new User Group</Button>
        <Popover title={"Define new User Group"} open={!!anchorEl}
                 anchorEl={anchorEl} onClose={handleClose}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'left',
                 }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                 }}>
            <Card>
                <CardContent style={{margin: 0, paddingBottom: 0}}>
                    <TextField
                        id="standard-full-width"
                        autoFocus
                        // label={title}
                        style={{margin: 8}}
                        helperText={"User Group Name"}
                        fullWidth
                        margin="normal"
                        value={newValue}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setNewValue(e.target.value);
                        }}
                    />
                </CardContent>
                <CardActions style={{display: "flex", justifyContent: "center"}}>
                    <Button size={"small"} onClick={handleClose}>Cancel</Button>
                    <Button size={"small"} color="secondary" onClick={handleSave} disabled={ !newValue }>Save</Button>
                </CardActions>
            </Card>
        </Popover>
    </React.Fragment>);
};

export default AddGroupButton;