import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Container} from '@material-ui/core';
import TemplateMenu from "./TemplateMenu";

const useStyles = makeStyles({
    root: {
        paddingTop: 56,
        height: '100%',
    },
    content: {
        height: '100%'
    }
});

const TemplateLayout = props => {
    const {children, menuIndex} = props;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <TemplateMenu menuIndex={menuIndex}/>
            <Container>
                <div className={classes.content}>
                    {children}
                </div>
            </Container>
        </div>
    )
};

TemplateLayout.propTypes = {
    children: PropTypes.node
};

export default TemplateLayout;
