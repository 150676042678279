import React, { useState } from "react";
// import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@material-ui/core';

export const MultipleChoice = ({
  updateOptions,
  questionOptions,
  isPreview,
  questionId,
  onChange
}) => {
  const [options, setOptions] = useState(questionOptions);
  const [textValue, setTextValue] = useState("");

  const handleOptionAdd = () => {
    if (!isPreview) {
      if (textValue.trim().length === 0) return;
      setTextValue("");
      setOptions([...options, { label: textValue, value: false }]);
      updateOptions([...options, { label: textValue, value: false }]);
    }
  };

  const changeOptionValue = (val) => {
    if (!isPreview) {
      setTextValue(val.target.value);
    }
  };

  const handleOptionRemove = (index) => {
    if (!isPreview) {
      let optionArray = options;
      optionArray = optionArray.filter(a => a !== optionArray[index] ) ;
      setOptions(optionArray);
      updateOptions(optionArray);
    }
  };

  return (
    <div>
      {/* <h6 className="mb-3">Select one option</h6> */}
      { isPreview === false ? (
        <div>
          <div className="qus-options-type-area">
            <div className="input-group">
              <div className="input-group-text">
                <input
                  type="radio"
                  aria-label="Radio button for following text input"
                />
              </div>
              <input
                type="text"
                value={textValue}
                onChange={changeOptionValue}
                className="form-control"
              />
            </div>
            { isPreview === false ? <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleOptionAdd}
            >
              +
            </button> : ''}
          </div>
        </div>
      ) : (
        ""
      )}


      {/*{questionOptions.map((option, index) => (*/}
      {/*    <div className="view-options-type-area">*/}
      {/*    <FormControl component="fieldset">*/}
      {/*      /!*<FormLabel component="legend">Gender</FormLabel>*!/*/}
      {/*      <RadioGroup*/}
      {/*          aria-label="gender"*/}
      {/*          defaultValue="female"*/}
      {/*          name="radio-buttons-group"*/}
      {/*      >*/}
      {/*        <FormControlLabel value="female" control={<Radio />} label="Female" />*/}
      {/*      </RadioGroup>*/}
      {/*    </FormControl>*/}

      {/*      { isPreview === false ?  <button*/}
      {/*          type="button"*/}
      {/*          className="btn btn-sm btn-secondary"*/}
      {/*          onClick={() => handleOptionRemove(index)}*/}
      {/*      >*/}
      {/*        -*/}
      {/*      </button> : ''}*/}
      {/*    </div>*/}
      {/*))}*/}



      {/*  ---------------- *********** ----------*/}
      {questionOptions.map((option, index) => (
        <div className="view-options-type-area" key={index}>
          <div className="input-group">
            <div className="input-group-text">
              <input
                className=""
                type="radio"
                aria-label="Radio button for following text input"
                name={"multi-select"+questionId}
                onChange={() => {
                  onChange(option.label);
                }}
              />
            </div>
            <label className="lb-name">{option && option.label ? option.label : ''}</label>
          </div>
          { isPreview === false ?  <button
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={() => handleOptionRemove(index)}
          >
            -
          </button> : ''}
        </div>
      ))}
    {/*  ---------------- *********** ----------*/}



    </div>
  );
};
