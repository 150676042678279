import {useHistory} from "react-router-dom";
import * as React from "react";
import createSurvey from "../../assert/images/survey.svg"
import SurveyList from "./SurveyList";
import CardContent from "@material-ui/core/CardContent";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {loadTemplates} from "../../actions/SurveyActions";


const SurveyHome = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [templates, setTemplates] = useState({surveyList:[],archiveSurvey:0});
    useEffect(() => {
        dispatch(loadTemplates('A', setTemplates))
    }, [dispatch]);


    return <>{templates.surveyList.length === 0 && templates.archiveSurvey === 0 ?
        <div>
            <div className="survey-home-container container">
                <div className="survey-card p-4">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="survey-home-card-header">NO SURVEYS CREATED</h2>
                        </div>
                    </div>

                    <CardContent>
                        <div className="row survey-home-item">
                            <div className="card-img-top ">
                                <img alt="create-survey-img" src={createSurvey}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 btn-create">
                                <button className="btn btn-primary" onClick={() => history.push(`/createSurvey?status=true`)}> Create</button>
                            </div>
                        </div>

                    </CardContent>


                </div>
            </div>
        </div>

        :
        <SurveyList/>

    }</>;

}

export default SurveyHome;

