import React, {useState} from "react";
import {FormControlLabel, Radio, TextField, RadioGroup, createMuiTheme} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

export const YesNoComment = ({onChange}) => {

    const [value, setValue] = React.useState('');
    const [description, setDescription] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value+" / "+description);
    };
    const handleDescription = (event) => {
        setDescription(event.target.value);
        // onChange({"selected": event.target.value, "description": "Test"});
        onChange(value+" / "+event.target.value);
    };

    return (
        <div>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}>
                    <FormControlLabel value="Yes" control={<Radio color={"primary"}/>} label="Yes"/>
                    <FormControlLabel value="No" control={<Radio color={"primary"}/>} label="No"/>
                </RadioGroup>
            </FormControl>
            <TextField fullWidth id="fullWidth" label="" variant="outlined"
                       onChange={handleDescription}/>
        </div>
    );
};
