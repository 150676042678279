import React, {useEffect, useState} from 'react';
import {
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs
} from '@material-ui/core';
import {useDispatch} from "react-redux";
import {loadSurveyResults} from "../../../actions/SurveyActions";
import parse from "html-react-parser";
import {CSVLink} from "react-csv";
import downloadExcel from "../../../assert/images/export-excel.png"

const UserSurveyResultTable = ({productSurveyId}) => {
    let headerStyle = {backgroundColor: 'aliceblue'};
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [csvData, setCsvData] = useState([]);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(loadSurveyResults({productSurveyId}, (data) => {
            setData(data);
            setHeaders(data);
        }));
    }, [dispatch, productSurveyId]);

    // const handleClick = (fileName) => {
    //     downloadAttachment(fileName).then((response) => {
    //         if (response) {
    //             const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    //             const link = document.createElement('a');
    //             link.href = downloadUrl;
    //             link.setAttribute('download', fileName);
    //             document.body.appendChild(link);
    //             link.click();
    //             link.remove();
    //         }
    //     });
    // };

    const setHeaders = (resData) => {
        let headers = ["USER"];
        let tableData = [];
        let userData = [];

        //---Create Headers
        const userRes = JSON.parse(resData[0].response).answers;
        userRes.forEach(resData => {
            headers.push(parse(resData.question).props.children);
        });


        // for (let user of resData) {
        //
        //     const answers = JSON.parse(user.response).answers;
        //     for (let answer of answers) {
        //         // console.log('Headers ',headers);
        //         if (!headers.includes(answer.question)) {
        //             headers.push(parse(answer.question).props.children);
        //         }
        //     }
        // }

        for (let user of resData) {
            userData = [];

            for (let header of headers) {

                if (header !== "USER") {
                    const answers = JSON.parse(user.response).answers;

                    let question = answers.find(a => parse(a.question).props.children === header);
                    if (question) {
                        userData.push(question.answer);
                    } else {
                        userData.push('-')
                    }

                } else {
                    userData.push(user.user);
                }
            }
            tableData.push(userData);

        }


        tableData = [headers].concat(tableData);
        if (csvData.length === 0) {
            setCsvData(tableData);
        }


    }

    const [size, setSize] = useState(100);

    const handlePopoverOpen = (event) => {
        setSize(500);
    };
    const handlePopoverClose = () => {
        setSize(100);
    };

    return (
        <>
            <Tabs
                style={{backgroundColor: 'skyblue'}}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered>

                <Tab label="Results"/>
                {/*<Tab label="Download Result"></Tab>*/}

            </Tabs>
            <CSVLink
                data={csvData}
                separator={","}
                filename={"survey-result.csv"}
                className="btn"
                target="_blank"
            >
                <img className="excel-icon" alt="download-excel-img" src={downloadExcel}/>
            </CSVLink>


            <Table size="small">
                <TableHead>
                    <TableRow>
                        {/*<TableCell style={headerStyle}>User</TableCell>*/}
                        {csvData.length > 0 ? csvData[0].map((key, id) => (
                            <TableCell key={id}
                                       style={{...headerStyle, textTransform: 'capitalize'}}>{parse(key)}</TableCell>
                        )) : ''}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {csvData.map((row, index) => {
                        if (index !== 0) {
                            return (
                                <TableRow key={index} hover={true}>
                                    {row.map((ans, index) => (
                                        <TableCell key={index} style={{wordBreak: 'break-all'}}>
                                            {ans.includes("<img") ?

                                                <img src={`data:image/jpeg;base64,${ans.substr(4)}`}
                                                    style={{overflow: "hidden", width: size}}
                                                     onMouseEnter={handlePopoverOpen}
                                                     onMouseLeave={handlePopoverClose}
                                                />

                                                : ans}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        }
                    })}
                </TableBody>
            </Table>

        </>
    )
};

export default UserSurveyResultTable;
