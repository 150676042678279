import React, {useEffect, useState} from 'react';
import {Card, CardActions, CardContent, Divider, Grid} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import CustomerSelect from "../../../components/CustomerSelect";
import {deleteProduct, loadProducts, saveProduct} from "../../../actions/ProductActions";
import {useDispatch} from "react-redux";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from "@material-ui/core/IconButton";
import {Prompt} from "react-router-dom";

const EditProductCard = ({onCancel, onSave, product = {}}) => {
    const [data, setData] = useState(product);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isDataChanged, setDataChanged] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(data.type !== 'web'){
            setData({...data, type: 'web'});
        }

    });

    const handleProductCardState = (fieldValue, eventValue) => {
        if (fieldValue !== eventValue) {
            setIsDisabled(false);
            setDataChanged(true);
        } else {
            setIsDisabled(true);
            setDataChanged(false);
        }
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };
    const handleSave = () => {
        dispatch(saveProduct({...data}, () => {
            onSave && onSave();
            dispatch(loadProducts());
            handleCancel();
        }));
        setDataChanged(false);
    };
    const handleDelete = () => {
        dispatch(deleteProduct(data.id, () => dispatch(loadProducts())));
    };
    return (
            <div>
                <Prompt when={isDataChanged} message={()=>"There are some unsaved changes. Do you want to go away?"}></Prompt>
                <Card>
                    <CardContent style={{height: 300, padding: 5}}>
                        <TextField label="Title" value={data.title || ""} fullWidth
                                   onChange={e => {
                                        handleProductCardState(product.title, e.target.value);
                                        setData({...data, title: e.target.value});
                                   }}
                                   required
                        />
                        <TextField label="Details" value={data.details || ""} fullWidth
                                   onChange={e => {
                                        handleProductCardState(product.details, e.target.value);
                                        setData({...data, details: e.target.value});
                                   }}/>
                        <TextField label="App Identifier" fullWidth
                                   value={data.appIdentifier || ""}
                                   onChange={e => {
                                        handleProductCardState(product.appIdentifier, e.target.value);
                                        setData({...data, appIdentifier: e.target.value});
                                   }}
                                   required
                        />
                        <CustomerSelect value={data.customerId || ""}
                                        onChange={value => {
                                            handleProductCardState(product.customerId, value);
                                            setData({...data, customerId: value})
                                        }}/>
                        <TextField label="Backend URL" fullWidth
                                   value={data.backendUrl || ""}
                                   onChange={e => {
                                        handleProductCardState(product.backendUrl, e.target.value);
                                        setData({...data, backendUrl: e.target.value});
                                   }}
                                   required
                        />
                        {/*<FormControl fullWidth required >*/}
                        {/*    <InputLabel>App Type</InputLabel>*/}
                        {/*    <Select value={data.type || ""} onChange={e => {*/}
                        {/*        handleProductCardState(product.type, e.target.value);*/}
                        {/*        setData({...data, type: e.target.value});*/}
                        {/*    }}>*/}
                        {/*        <MenuItem value='tablet'><TabletMacIcon/> Mobile App</MenuItem>*/}
                        {/*        <MenuItem value='web'><LaptopMacIcon/> Web App</MenuItem>*/}
                        {/*    </Select>*/}
                        {/*</FormControl>*/}
                    </CardContent>
                    <Divider/>
                    <CardActions>
                        <Grid container justify="space-between">
                            <Button size="small" color="primary" onClick={handleCancel}>
                                Cancel
                            </Button>
                            {data.id &&
                            <IconButton size="small" color={"secondary"} onClick={handleDelete}>
                                <DeleteForeverIcon/>
                            </IconButton>
                            }
                            <Button size="small" color="primary" onClick={handleSave}
                                    disabled={
                                        isDisabled || !data.title || !data.type || !data.backendUrl || !data.customerId || !data.appIdentifier
                                    }>
                                Save
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            </div>
    );
};

export default EditProductCard;
