import React, {useEffect, useState} from "react";
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import CustomerSelect from "../../components/CustomerSelect";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "../../hooks/useQuery";
import {
    getAllUserGroups,
    loadProductByCustomerId,
    loadProductUsers,
    newUserGroups,
    updateUserGroups
} from "../../actions/ProductActions";
import ProductSelect from "../../components/ProductSelect";
import UsersTable from "./UsersTable";
import {useHistory} from "react-router-dom";

const CreateRespondents = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const query = useQuery();
    const groupId = query.get("groupId");


    const [productId, setProductId] = useState(0);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(1000);

    const products = useSelector(state => state.product);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [group, setGroup] = useState({
        id: 0,
        name: "",
        customerId: null,
        productId: null,
        users: [],
        isLoaded: false
    });

    const [checkedUsers, setCheckedUsers] = useState([]);

    const isEdited = groupId ? true : false;

    useEffect(() => {
        if (groupId && group.isLoaded === false) {
            dispatch(getAllUserGroups((groups) => {
                const uGroup = groups.find((g) => g.id === parseInt(groupId));
                if (uGroup) {
                    uGroup['isLoaded'] = true;
                    loadUserGroups(uGroup);
                }
            }));
        }
    });

    const filterUsers = (search) => {
        let userList = users;
        if (search !== '') {
            userList = userList.filter(item =>
                ((item.firstName != null) ? (item.firstName.toString().toLowerCase().indexOf(search.toLowerCase()) != -1) : null) ||
                ((item.lastName != null) ? (item.lastName.toString().toLowerCase().indexOf(search.toLowerCase()) != -1) : null) ||
                ((item.email != null) ? (item.email.toString().toLowerCase().indexOf(search.toLowerCase()) != -1) : null)
            );
            setFilteredUsers(userList);
        }else{
            setFilteredUsers(users);
        }


    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadUserGroups = (uGroup) => {
        setGroup(uGroup);
        dispatch(loadProductByCustomerId(uGroup.customerId));
        const productId = uGroup.productId;
        mapUsers(productId, uGroup.users);
    }

    const handleChangeGroupName = (ugName) => {
        setGroup({...group, name: ugName});
    }

    const handleCustomer = (customerId) => {
        dispatch(loadProductByCustomerId(customerId));
        let userGroup = group;
        userGroup.customerId = customerId;
        userGroup.productId = null;
        setGroup(userGroup);
        setUsers([]);
        setFilteredUsers([]);
    };

    const handleProduct = (productId) => {
        let userGroup = group;
        userGroup.productId = productId;
        setGroup(userGroup);
        mapUsers(productId, userGroup.users);
    };

    const mapUsers = (productId, checkedUsers) => {
        dispatch(loadProductUsers({productId, page, pageSize}, (data) => {
            let users = data.items;
            setTotal(data.total);
            for (let user of users) {
                user['assigned'] = checkedUsers.includes(user.id);
                // let isChecked =  checkedUsers.includes(user.id);
                // user['assigned'] = isChecked;
            }
            setUsers(users);
            setFilteredUsers(users);
            setProductId(productId);
            setCheckedUsers(checkedUsers);
        }));
    }


    const handleCheckedUsers = (checkedUsers) => {
        let userGroup = group;
        // userGroup.users = checkedUsers.filter(u => u.assigned).map(u => u.id);
        userGroup.users = checkedUsers;
        setGroup(userGroup);
        // setFilteredUsers(checkedUsers);
    }

    const handleSave = () => {
        if (isEdited) {
            dispatch(updateUserGroups({
                id: group.id,
                name: group.name,
                productId: group.productId,
                users: group.users.toString()
            }, () => {
                alert("Respondent group is updated")
                history.push(`/respondents`);
            }));

        } else {
            dispatch(newUserGroups({
                name: group.name,
                productId: group.productId,
                users: group.users.toString()
            }, () => {
                alert("Respondent group is created")
                history.push(`/respondents`);
            }));
        }
    };


    return (
        <div className="container create-respondent-container">
            <div className="cr-header">Create Respondents Groups</div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div>Group Name:
                            <input type="text" value={group.name} onChange={e => {
                                handleChangeGroupName(e.target.value);
                            }}/>
                        </div>
                        <div className="row-cols-lg-1">

                            <Box className="cus-box">
                                <FormControl fullWidth>
                                    <CustomerSelect value={group.customerId}
                                                    onChange={value => {
                                                        handleCustomer(value)
                                                    }}
                                    />
                                </FormControl>
                            </Box>

                            {group.customerId !== null ?
                                <Box className="prod-box">
                                    <FormControl fullWidth>
                                        <ProductSelect products={products || []} value={group.productId}
                                                       onChange={value => {
                                                           handleProduct(value);
                                                       }}
                                        />
                                    </FormControl>
                                    {/*<div>*/}
                                    {/*    <button type="button" className="btn btn-link"*/}
                                    {/*            onClick={() => history.push('/all-products')}>Add new Application*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </Box>
                                : ''}

                            {users.length !== 0 ?
                                <div className="row-cols-lg-1">
                                    <UsersTable users={filteredUsers} setCheckedUsers={handleCheckedUsers}
                                                filter={filterUsers} checkedUsers={checkedUsers}/>
                                </div>
                            : ''
                            }

                            <Box className="save-btn">
                                <button onClick={handleSave} className="btn btn-secondary">SAVE</button>
                            </Box>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}
export default CreateRespondents;
