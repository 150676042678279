import {LOAD_ALL_CUSTOMERS} from "./types";
import {apiAction} from "./Api";

const BASE_URL = '/api/customer';

export function getAll(onSuccess) {
    return apiAction({
            url: `${BASE_URL}/all`,
            type: LOAD_ALL_CUSTOMERS,
            onSuccess
        }
    );
}