import React from 'react';
import {Route, useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const RouteWithLayout = props => {
    const {menuIndex, layout: Layout, component: Component, menuBar: MenuBar, ...rest} = props;

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const history = useHistory();
    if (isAuthenticated) {
        history.push("/login");
    }
    return (
        <Route
            {...rest}
            render={matchProps => (
                <Layout menuIndex={menuIndex} {...matchProps}>
                    <Component {...matchProps} />
                </Layout>
            )}
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

export default RouteWithLayout;
