import {LOAD_USER_GROUP, LOAD_USER_GROUPS} from '../actions/types';


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = [], action) => {
    switch (action.type) {
        case LOAD_USER_GROUPS:
            if(action.payload) {
                const userGroups = action.payload.map((g) => {
                    g.users = g.users.split(',').map(u => {
                        return parseInt(u)
                    });
                    return g;
                });
                return userGroups;
            }else{
                return [];
            }
        case LOAD_USER_GROUP:
            return action.payload;
        default:
            return state;
    }
};