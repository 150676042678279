import {SIGN_IN, SIGN_OUT} from '../actions/types';

const initialState = {
    isAuthenticated: false,
    user: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN:
            return {isAuthenticated: false};
        case SIGN_OUT:
            return {isAuthenticated: false};
        default:
            return state;
    }
};
