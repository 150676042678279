import React from 'react';
import {Container} from '@material-ui/core';
import MainMenu from "./MainMenu";

const MainLayout = props => {
    const {children, menuIndex} = props;

    return (
        <div style={{
            paddingTop: 56,
            height: '100%',
        }}>
            <MainMenu menuIndex={menuIndex}/>
            <Container>
                <div style={{
                    height: '100%'
                }}>
                    {children}
                </div>
            </Container>
        </div>
    );
};

export default MainLayout;
