import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import ReactQuill from "react-quill";
import {
    FILE_UPLOAD,
    MULTIPLE_CHOICE,
    PREFERENCE_PRIORITY,
    SCALE_TYPE,
    STAR_RATING,
    TEXT_AREA,
    YES_NO,
    YES_NO_COMMENT
} from "./AnswerTypes/AnswerTypes";
import {useDispatch, useSelector} from "react-redux";
import {removeQuestion, updateQuestion} from "../../actions/SurveyActions";
import {TextAreaType} from "./AnswerTypes/TextAreaType";
import {MultipleChoice} from "./AnswerTypes/MultipleChoice";
import StarRatingType from "./AnswerTypes/StarRatingType";
import ScaleType from "./AnswerTypes/ScaleType";
import PreferencePriority from "./AnswerTypes/PreferencePriority";
import {YesNo} from "./AnswerTypes/YesNo";
import FileUpload from "./AnswerTypes/FileUpload";
import { YesNoComment } from './AnswerTypes/YesNoComment';

const QuestionEditDialog = ({open, closeView, id}) => {

    const handleClose = () => {
        closeView(false);
    };

    const dispatch = useDispatch();

    const question = useSelector(state => state.survey.questions.find(q => q.id === id));

    const [editorState, setEditorState] = useState({text: question.title});

    const [answerType, setAnswerType] = useState(question.type);

    const handleAnswerType = (type) => {
        setAnswerType(type.target.value);
        let quest = question;
        quest.type = type.target.value;
        dispatch(updateQuestion(quest));
    }

    const handleMOAnswers = (options) => {
        let quest = question;
        quest.answers = options;
        dispatch(updateQuestion(quest));
    }

    const saveAllQuestions = () => {
        closeView(false);
    };

    const removeQuestions = () => {
        closeView(false);
    };

    //Cancel Button-----
    useEffect(() => {
    }, []);


    const onChangeEditor = (value) => {
        setEditorState({text: value});
        let quest = question;
        quest.title = value;
        dispatch(updateQuestion(quest));
    };

    const generateComponent =(questionType, onChange) => {
        switch (questionType) {
            case TEXT_AREA:
                return <TextAreaType/>;
            case MULTIPLE_CHOICE:
                return <MultipleChoice updateOptions={handleMOAnswers} isPreview={false} questionOptions={question.answers} />;
            case STAR_RATING:
                return <StarRatingType component={questionType} onChange={onChange}/>;
            case SCALE_TYPE:
                return <ScaleType/>;
            case PREFERENCE_PRIORITY:
                return <PreferencePriority updateOptions={handleMOAnswers} isPreview={false} questionOptions={question.answers} />;
            case YES_NO:
                return <YesNo onChange={onChange}/>;
            case YES_NO_COMMENT:
                return <YesNoComment onChange={onChange}/>
            case FILE_UPLOAD:
                return <FileUpload onChange={onChange}/>;
            default:
                return <TextAreaType/>;
        }
    }



    return (<Dialog open={open} onClose={handleClose} maxWidth={"xl"} aria-labelledby="form-dialog-title">

            <DialogTitle>Edit Question</DialogTitle>
            <DialogContent>
                <div className="container card edit-qus-card">
                <div className="edit-qus-body">

                    <div className="q-editor">
                        <ReactQuill value={editorState.text}
                                    placeholder="Enter the survey question here"
                                    onChange={onChangeEditor}/>
                    </div>

                    <div className="card-header">
                        <div className="q-no">Select answer type: </div>
                        <select className="form-select form-select-sm" aria-label="Default select example"
                                onChange={handleAnswerType} value={answerType}>
                            <option value={TEXT_AREA}>Textarea</option>
                            <option value={MULTIPLE_CHOICE}>Multiple choice</option>
                            <option value={STAR_RATING}>Star rating</option>
                            <option value={SCALE_TYPE}>Scale type</option>
                            <option value={PREFERENCE_PRIORITY}>Preference priority</option>
                            <option value={YES_NO}>Yes No</option>
                            <option value={YES_NO_COMMENT}>Yes No Comment</option>
                            <option value={FILE_UPLOAD}>File upload</option>
                        </select>
                    </div>

                    <div className="qus-options">
                        {generateComponent(answerType , ()=>{})}
                    </div>
                </div>
            </div>

            </DialogContent>

            <DialogActions>
                <button onClick={removeQuestions} className="btn btn-warning btn-cancel"> CANCEL </button>
                <button onClick={saveAllQuestions} className="btn btn-warning btn-save"> SAVE QUESTION</button>
            </DialogActions>
        </Dialog>
    )
};

export default QuestionEditDialog;
