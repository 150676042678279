import React, {useState} from 'react';
import {Button, Paper} from '@material-ui/core';
import {QueuePlayNext} from '@material-ui/icons';
import ScheduleSurveyDialog from "./ScheduleSurveyDialog";
import ProductSurveyTable from "./ProductSurveyTable";
import {useQuery} from "../../../hooks/useQuery";

const ProductSurveyConfiguration = () => {
    const [reload, setReload] = useState(1);
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const query = useQuery();
    const productId = query.get("productId");

    return (
        <Paper style={{padding: 10}}>

            <Button color="primary"
                    onClick={() => setOpenScheduleDialog(true)}
                    startIcon={<QueuePlayNext/>}> Schedule new survey for this product</Button>

            <ProductSurveyTable productId={productId} reload={reload}/>

            <ScheduleSurveyDialog open={openScheduleDialog} productId={productId} handleClose={() => {
                setOpenScheduleDialog(false);
                setReload(reload + 1)
            }}/>
        </Paper>
    )
};

export default ProductSurveyConfiguration;
