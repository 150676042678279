import React, {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import ItemTypes from "./ItemTypes";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import {removeQuestion, updateQuestion} from "../../actions/SurveyActions";
import {Delete, Edit} from "@material-ui/icons";
import {useDispatch} from "react-redux";

const style = {
    border: "1px dashed gray",
    padding: "0.5rem 1rem",
    marginBottom: ".5rem",
    backgroundColor: "white",
    cursor: "move"
};
const MyCard = ({id, question, index, moveCard, answerType, editQuestion, deleteQuestion}) => {
    const ref = useRef(null);
    const dispatch = useDispatch();

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.CARD,
        item: {id, index},
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));


    return (
        <CustomCard ref={ref} answer={answerType} style={{...style, opacity}}>
            <Card ref={ref} className="card view-qc">
                <CardActionArea className="card-header">
                    <Typography gutterBottom className="q-title">
                        Q{index + 1}. &nbsp; {question}
                    </Typography>
                    <div>
                        <IconButton onClick={() => editQuestion(id)} aria-label="settings">
                            <Edit className="edit"/>
                        </IconButton>
                        <IconButton onClick={() => deleteQuestion(id)} aria-label="settings">
                            <Delete className="delete"/>
                        </IconButton>
                    </div>
                </CardActionArea>
                <CardContent className="card-body">
                    {answerType}
                </CardContent>
            </Card>
        </CustomCard>
    );
};
export default MyCard;

const CustomCard = React.forwardRef((props,
                                     ref) => (

    <Card ref={ref}>
        <CardActionArea>
            <Typography gutterBottom variant="h5" component="h2">
                {props.children}
            </Typography>
        </CardActionArea>
        {/*<CardActions>*/}
        {/*    <Button size="small" color="primary">*/}
        {/*        Share*/}
        {/*    </Button>*/}
        {/*    <Button size="small" color="primary">*/}
        {/*        Learn More*/}
        {/*    </Button>*/}
        {/*</CardActions>*/}
    </Card>
));
