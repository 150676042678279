import React, {useState} from 'react';
import {Card, CardActions, Divider, Grid, TextField} from '@material-ui/core';
import Button from "@material-ui/core/Button";


const EditTemplateCard = ({onSave, onCancel, code = ""}) => {
    const [data, setData] = useState({code});

    return (
        <Card>
            <Grid style={{height: 143, padding: 15}}>
                <TextField label="Title" fullWidth value={data.code}
                           onChange={e => setData({...data, code: e.target.value})}/>
            </Grid>
            <Divider/>
            <CardActions>
                <Grid container justify="space-between">
                    <Button size="small" color="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button size="small" color="primary" onClick={() => onSave(data)} disabled={!data.code}>
                        Save
                    </Button>
                </Grid>
            </CardActions>
        </Card>
    );

};

export default EditTemplateCard;
