import React from 'react';
import {Box, Typography} from '@material-ui/core';


const LabelBox = ({label}) => {
    return (
        <Box mb={3} borderColor="transparent">
            <Typography component="legend">{label}</Typography>
        </Box>
    );
};

export default LabelBox;