import * as React from 'react';
import {Box, Slider} from '@material-ui/core';
import {useState} from "react";

function valuetext(value) {
    return `${value}°C`;
}

const ScaleType = ({onChange}) => {
    const [val, setVal] = useState(5);

    return (
        <Box sx={{ width: 300 }}>
            <Slider
                aria-label="Temperature"
                value={val}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={10}

                onChange={(event, newValue) => {
                    onChange(newValue.toString());
                    setVal(newValue);
                }}

            />
            {/*<Slider defaultValue={50} step={10} marks min={10} max={100} disabled />*/}
        </Box>
    );

}
export default ScaleType;