import React, {useState} from 'react';
import {Card, CardActionArea, CardContent, Divider, Grid, Typography} from '@material-ui/core';
import {Doughnut} from "react-chartjs-2";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import SettingsIcon from '@material-ui/icons/Settings';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import {openNewWindow} from "./Utils";
import {useHistory} from "react-router-dom";
import EditTemplateCard from "./EditTemplateCard";
import {copyTemplate, loadTemplates, saveTemplate} from "../../actions/SurveyActions";
import {useDispatch} from "react-redux";

const TemplateCard = ({template}) => {
    const [editing, setEditing] = useState(false);
    let history = useHistory();
    const dispatch = useDispatch();

    const handleSave = (data) => {
        setEditing(false);
        dispatch(saveTemplate({...data, id: template.id}, () => dispatch(loadTemplates())));
    };
    const card = () => {
        return (
            <Card>
                <CardActionArea>
                    <CardContent>
                        <Typography variant="h6">
                            {template.code}
                        </Typography>
                        <Doughnut legend={{display: true, position: "top"}} data={{
                            labels: [
                                'Submitted',
                                'Pending'
                            ],
                            datasets: [{
                                data: [
                                    template.usersSubmitted,
                                    template.usersAssigned
                                ],
                                backgroundColor: [
                                    '#5fff3a',
                                    '#5968ff'
                                ],
                                hoverBackgroundColor: [
                                    '#c2ff5a',
                                    '#87c9ff'
                                ]
                            }]
                        }}/>
                    </CardContent>
                </CardActionArea>
                <Divider/>
                <CardActions>
                    <Grid container justify="space-between">
                        <Button size="small" color="primary" onClick={() => setEditing(true)}>
                            <EditIcon fontSize="small"/> Edit
                        </Button>
                        <Button size="small" color="primary"
                                onClick={() => history.push(`/survey/template/edit?templateId=${template.id}`)}>
                            <SettingsIcon fontSize="small"/> Config
                        </Button>
                        <Button size="small" color="primary" onClick={() => {
                            dispatch(copyTemplate({id: template.id}, () => dispatch(loadTemplates())))
                        }}>
                            <FileCopyIcon fontSize="small"/> Copy
                        </Button>

                        <Button size="small" color="primary" onClick={() => openNewWindow(template.path)}>
                            TRY IT
                        </Button>
                    </Grid>
                </CardActions>
            </Card>
        );
    };

    return <>{editing ?
        <EditTemplateCard onSave={handleSave} onCancel={() => setEditing(false)} code={template.code}/> : card()}</>;
};

export default TemplateCard;

