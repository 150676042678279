import React from 'react';
import TextField from "@material-ui/core/TextField";

const TitleLabelConfig = ({component, onChange}) => {
    return (<div style={{width: 400}}>
            <TextField label="Title" value={component.title || ""} fullWidth
                       onChange={e => onChange({...component, title: e.target.value})}/>
            <TextField label="Label" value={component.label || ""} fullWidth
                       onChange={e => onChange({...component, label: e.target.value})}/>
        </div>
    );
};

export default TitleLabelConfig;