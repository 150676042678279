import React from "react";
import AddQuestion from "./AddQuestion";
import Preview from "./PreviewSurvey";
import {useQuery} from "../../hooks/useQuery";

const CreateSurvey = () => {
  const query = useQuery();
  const questionStatus = query.get("status");

  return (
    <div>
      <div className="container create-survey-container">
        <div className="col-lg-12 text-center"><h2 className="cs-page-header">CREATE SURVEY</h2></div>
        <div className="row cs-row">

          {/*<div className="col-lg-2 library-div p-0">*/}
          {/*  <div className="library-header p-2">*/}
          {/*    <h6 className="mb-0">Menu</h6>*/}
          {/*  </div>*/}
          {/*  <ul className="list-group mt-2">*/}
          {/*    <li className="list-group-item">*/}
          {/*      <span>Images</span>*/}
          {/*      <button>+</button>*/}
          {/*    </li><li className="list-group-item">*/}
          {/*      <span>Question Bank</span>*/}
          {/*      <button>+</button>*/}
          {/*    </li>*/}
          {/*  </ul>*/}

          {/*</div>*/}

          <div className="col-lg-5 p-0 qus-cr-div">
            <AddQuestion status={questionStatus}/>
          </div>

          <div className="col-lg-5 preview-div">
            <Preview/>
          </div>

        </div>

      </div>
    </div>

);

}

export default CreateSurvey;
