import React, {useEffect, useState} from 'react';
import {loadSurvey} from "../../actions/SurveyActions";
import {useQuery} from "../../hooks/useQuery";
import {useDispatch} from "react-redux";
import Alert from "@material-ui/lab/Alert";
import RenderSurvey from "./RenderSurvey";


const RenderSurveyHome = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const templateId = query.get("templateId");
    const submitted = query.get("submit");
    const [templateConfig, setTemplateConfig] = useState(null);
    const [surveyData, setSurveyData] = useState(null);
    const [countTime, setCountTime] = useState(false);

    useEffect(() => {
        dispatch(loadSurvey(templateId, (data) => {
            setTemplateConfig(data.questions ? JSON.parse(data.questions) : null);
            setSurveyData(data);
        }));
    }, [dispatch, templateId]);

    const handleWindowClose = () => {
        window.close();
    }

    return submitted ?
        <Alert className="submit-alert" severity="success">
            {<div className="alert-body">
                <h2>Survey submitted.</h2>
                <p align="center"><h2>Thank you for your time &#128578;.</h2></p>
                <button type="button" onClick={handleWindowClose} className="btn btn-success">Ok</button>

            </div>
            }
        </Alert>
        :
        templateConfig ? <RenderSurvey survey={surveyData} questions={templateConfig}/> : <div/>
};

export default RenderSurveyHome;