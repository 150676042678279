import {
    SET_SURVEY_TITLE,
    REMOVE_QUESTION,
    ADD_QUESTION,
    UPDATE_QUESTION,
    LOAD_SURVEY,
    SET_SURVEY_NAME,
    SET_LEFT_LOGO,
    SET_RIGHT_LOGO, CLEAR_SURVEY_VIEW, SET_SURVEY_DESCRIPTION, RESET_QUESTION_ORDER

} from "../actions/types";

const initialState = {
    surveyId: 0,
    surveyName: '',
    surveyTitle: '',
    surveyDescription: '',
    leftLogo: '',
    rightLogo: '',
    isEdit: false,
    questions: [
        // {
        //     id : 1,
        //     type: 'mul',
        //     title: 'What is your name?',
        //     answers: []
        // }
    ]
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type){
        case SET_SURVEY_TITLE:
            action.payload === '' ? state.isEdit = false : state.isEdit = true;
            return {...state, surveyTitle: action.payload};

        case SET_SURVEY_DESCRIPTION:
            action.payload === '' ? state.isEdit = false : state.isEdit = true;
            return {...state, surveyDescription: action.payload};

        case SET_SURVEY_NAME:
            return {...state, surveyName: action.payload};

        case SET_LEFT_LOGO:
            return {...state, leftLogo: action.payload};

        case SET_RIGHT_LOGO:
            return {...state, rightLogo: action.payload};

        case ADD_QUESTION:
            state.isEdit = true
            return {...state, questions: [...state.questions,action.payload]};

        case REMOVE_QUESTION:
            state.isEdit = true
            let newRemoveItems = [...state.questions];
            newRemoveItems =  newRemoveItems.filter(q => q.id !== action.payload.id);
            return {...state, questions: newRemoveItems};

        case UPDATE_QUESTION:
            state.isEdit = true
            let newItems = [...state.questions];
            // let updateItem =  newItems.filter(q => q.id === action.payload.id);
            // updateItem = action.payload;
            return { ...state,questions: newItems};

        case RESET_QUESTION_ORDER:
            state.isEdit = true
            return { ...state,questions: action.payload};

        case LOAD_SURVEY:
            state.surveyId = action.payload.surveyId;
            state.surveyName = action.payload.surveyName;
            state.surveyTitle = action.payload.surveyTitle;
            state.surveyDescription = action.payload.surveyDescription;
            state.leftLogo = action.payload.leftLogo;
            state.rightLogo = action.payload.rightLogo;
            state.questions = JSON.parse(action.payload.questions);
            state.isEdit=true;
            return {...state}

        case CLEAR_SURVEY_VIEW:
            return getEmptyState();

        default:
            return state;
    }
}

const getEmptyState = () => {
        return {
            surveyId: 0,
            surveyName: '',
            surveyTitle: '',
            surveyDescription: '',
            leftLogo: '',
            rightLogo: '',
            isEdit: false,
            questions: []
        }
}
