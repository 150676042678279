import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import {Edit, QuestionAnswer, Delete, Add} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {copyUserGroup, deleteUserGroup, getAllUserGroups, loadGroupUsers} from "../../actions/ProductActions";
import RespondentsDialog from "./RespondentsDialog";


const RespondentsList =() =>{
  const history = useHistory();
  const dispatch = useDispatch();
  const groups = useSelector(state => state.userGroup);
  const [openRespondentsDialog, setOpenRespondentsDialog] = useState(false);
  const [userGroupId, setUserGroupId] = useState(0);
  const [users, setUsers] = useState([]);


  useEffect(() => {
    dispatch(getAllUserGroups())
  }, [dispatch]);

  const handleDelete = (groupId) => {
    dispatch(deleteUserGroup(groupId, () => {
      alert("Respondent Group Deleted")
      history.push(`/respondents`);
      window.location.reload();
    }));
  };

  const editGroup = (groupId) => {
    history.push(`/createRespondents?groupId=${groupId}`);

  }

  const getUsers = (groupId) => {
            dispatch(loadGroupUsers({groupId}, (data) => {
                setUsers(data);
            }));
  }

  return(
    <div>

      <div className="respondent-list-container container">
        <div className="row">
          <div className="col-lg-12 text-right">
            <h2 className="respondent-list-card-header">RESPONDENT GROUPS</h2>
          </div>
        </div>

        <Card className="respondent-list-card p-4">
          <CardContent>
            {groups && groups.map((group, index) => (

            <div className="row rg-item" key={index}>
              <div className="col-lg-5 rg-item-name" style={{cursor: 'pointer'}}>

                <a href=''><h5 onClick={() => history.push(`/respondents/survey?groupId=${group.id}`)}>
                    {group.name}
                </h5></a>

              </div>
              <div className="col-lg-2 text-center rg-res-count" style={{cursor: 'pointer'}}>
                {/*<a href=''>*/}
                  <h6 onClick={() => {setOpenRespondentsDialog(true); getUsers(group.id)}}>
                    {group.respondents}
                {/*</a>*/}<br/>
                  Respondents</h6>

              </div>
              <div className="col-lg-2 text-center rg-res-sur">
                <h6>{group.surveys}</h6>
                <h6>Assigned Surveys</h6>
              </div>
              <div className="col-lg-3 rg-btn-area">
                <IconButton onClick={()=> editGroup(group.id)} aria-label="settings">
                  <Edit className="edit" />
                </IconButton>
                <IconButton onClick={() => {
                    dispatch(copyUserGroup({id: group.id}, () => dispatch(getAllUserGroups())))
                  }} aria-label="settings">
                  <QuestionAnswer className="question-answer"/>
                </IconButton>
                <IconButton onClick={() =>  handleDelete(group.id)} aria-label="settings">
                  <Delete className="delete"/>
                </IconButton>
              </div>
            </div>

            ))}

          </CardContent>

          <div className="row">
            <div className="col-lg-12 rg-btn-create">
              <button className="btn btn-primary" onClick={() => history.push(`/createRespondents`)}><Add className="add" /> Create</button>
            </div>
          </div>
        </Card>
      </div>

      <RespondentsDialog open={openRespondentsDialog} onClose={() => {setOpenRespondentsDialog(false);}} groupUsers={users}/>

    </div>
  );
}
export default RespondentsList;



