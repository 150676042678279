import React from 'react';
import {Button, Dialog, DialogActions, DialogTitle} from '@material-ui/core';
import {useHistory} from "react-router-dom";

const SaveSurveyDialog = ({open, sId}) => {
    const history = useHistory();

    const handleClose = () => {
        history.push(`/surveys`);
    };

    const handleSave = () => {
        history.push(`/assignSurvey?templateId=${sId}`)
    };


    return (<Dialog open={open} onClose={handleClose} maxWidth={"xs"} aria-labelledby="form-dialog-title">

            <DialogTitle>Do you wish to send this survey to the respondents now?</DialogTitle>
 
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Not Now
                </Button>
                <Button color="primary" onClick={handleSave} >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default SaveSurveyDialog;
