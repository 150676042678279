import {
    ASSIGN_PRODUCT_SURVEY_TO_USER,
    CHANGE_PRODUCT_USER_GROUP,
    CLEAN_PRODUCT,
    LOAD_PRODUCT,
    LOAD_PRODUCT_ACTIVITIES,
    LOAD_PRODUCT_SURVEYS,
    LOAD_PRODUCT_USER_GROUPS,
    LOAD_PRODUCT_USER_SURVEYS,
    LOAD_PRODUCT_USERS,
    LOAD_PRODUCTS,
    NEW_PRODUCT_USER_GROUP,
    SAVE_PRODUCT,
    SCHEDULE_PRODUCT_SURVEY,
    LOAD_USER_GROUPS, LOAD_USER_GROUP, LOAD_GROUP_USERS
} from "./types";
import {apiAction} from "./Api";

const BASE_URL = '/api/product';

export function loadProducts() {
    return apiAction({
            url: `${BASE_URL}/all`,
            type: LOAD_PRODUCTS
        }
    );
}

export function saveProduct(data, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/save`,
            method: "POST",
            type: SAVE_PRODUCT,
            data,
            onSuccess
        }
    );
}

export function deleteProduct(id, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/delete`,
            method: "DELETE",
            type: "DELETE PRODUCT",
            data: {id},
            onSuccess
        }
    );
}

export function loadProduct(id) {
    return apiAction({
            url: `${BASE_URL}?id=${id}`,
            type: LOAD_PRODUCT
        }
    );
}

export function loadProductByCustomerId(id) {
    return apiAction({
            url: `${BASE_URL}/customers?id=${id}`,
            type: LOAD_PRODUCT
        }
    );
}


export function cleanProduct() {
    return {
        type: CLEAN_PRODUCT,
        payload: null
    }
}

export function loadProductActivities(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/activities`,
            data: params,
            type: LOAD_PRODUCT_ACTIVITIES,
            onSuccess
        }
    );
}

export function loadProductUsers(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/users`,
            data: params,
            type: LOAD_PRODUCT_USERS,
            onSuccess
        }
    );
}

export function loadGroupUsers(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/groupUsers`,
            data: params,
            type: LOAD_GROUP_USERS,
            onSuccess
        }
    );
}

export function loadProductSurveys(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/surveys`,
            data: params,
            type: LOAD_PRODUCT_SURVEYS,
            onSuccess
        }
    );
}

export function changeSurveyStatus(data, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/survey/status/update`,
            method: "PUT",
            data: data,
            type: LOAD_PRODUCT_SURVEYS,
            onSuccess
        }
    );
}

export function loadProductSurveysUsers(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/user/surveys`,
            data: params,
            type: LOAD_PRODUCT_USER_SURVEYS,
            onSuccess
        }
    );
}

export function getUserGroups(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/user/groups`,
            data: params,
            type: LOAD_PRODUCT_USER_GROUPS,
            onSuccess
        }
    );
}

export function getAllUserGroups(onSuccess) {
    return apiAction({
            url: `${BASE_URL}/user/groups/All`,
            type: LOAD_USER_GROUPS,
           onSuccess
        }
    );
}

export function deleteUserGroup(groupId, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/user/group/delete`,
            method: 'DELETE',
            data: {groupId},
            type: LOAD_USER_GROUPS,
            onSuccess
        }
    );
}

export function copyUserGroup(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/user/group/copy`,
            method: 'POST',
            data: params,
            type: "COPY USER GROUP",
            onSuccess
        }
    );
}

export const loadUserGroup = (group) => {
    return (dispatch) => {
        dispatch({
            type: LOAD_USER_GROUP,
            payload: group
        });
    }
}

export function newUserGroups(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/user/group/new`,
            method: 'POST',
            data: params,
            type: NEW_PRODUCT_USER_GROUP,
            onSuccess
        }
    );
}

export function updateUserGroups(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/user/group/update`,
            method: 'PUT',
            data: params,
            type: NEW_PRODUCT_USER_GROUP,
            onSuccess
        }
    );
}

export function changeUserGroup(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/user/group/change`,
            method: 'POST',
            data: params,
            type: CHANGE_PRODUCT_USER_GROUP,
            onSuccess
        }
    );
}

export function scheduleProductSurvey(params, onSuccess, onFailure) {
    return apiAction({
            url: `${BASE_URL}/user/group/survey-schedule`,
            method: 'POST',
            data: params,
            type: SCHEDULE_PRODUCT_SURVEY,
            onSuccess,
            onFailure
        }
    );
}

export function updateScheduleProductSurvey(params, onSuccess, onFailure) {
    return apiAction({
            url: `${BASE_URL}/user/group/survey-schedule`,
            method: 'PUT',
            data: params,
            type: SCHEDULE_PRODUCT_SURVEY,
            onSuccess,
            onFailure
        }
    );
}

export function assignUserSurvey(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/survey/assign`,
            method: 'POST',
            data: params,
            type: ASSIGN_PRODUCT_SURVEY_TO_USER,
            onSuccess
        }
    );
}