import React, {useState} from "react";
import {FormControlLabel, Radio, RadioGroup, createMuiTheme} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

interface yesNoAnswer {
    selected: string;
    description: string;
}

export const YesNo = ({onChange}) => {

    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
    };

    return (
        <div>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}>
                    <FormControlLabel value="Yes" control={<Radio color={"primary"}/>} label="Yes"/>
                    <FormControlLabel value="No" control={<Radio color={"primary"}/>} label="No"/>
                </RadioGroup>
            </FormControl>
        </div>
    );
};
