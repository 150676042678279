import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {getAllUserGroups} from "../actions/ProductActions";

const useStyles = makeStyles({
    formControl: {
        width: "100%"
    }
});

const GroupSelect = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getAllUserGroups(setData));
       
    }, [dispatch]);

    return (
        <FormControl size="small" className={classes.formControl} required>
            <InputLabel id="select-label">Respondent Group</InputLabel>
            <Select
                labelId="select-label"
                id="select"
                value={props.value || ''}
                onChange={e => props.onChange(e.target.value)}
            >
                {data.map(e => (<MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>))}
            </Select>
        </FormControl>
    )
};

GroupSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number
};

export default GroupSelect;