import React from 'react';
import Routes from './Routes';
import {SIGN_IN} from "./actions/types";
import {useDispatch} from "react-redux";
import {Router} from 'react-router-dom';
import {createBrowserHistory} from "history";
import {setAuthorizationToken} from "./actions/UserActions";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MessageHandlerComponent from "./components/MessageHandlerComponent";
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css'; 

const browserHistory = createBrowserHistory()
const App = () => {
    const dispatch = useDispatch();
    if (localStorage.jwtToken) {
        setAuthorizationToken(localStorage.jwtToken);
        dispatch({type: SIGN_IN});
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={browserHistory}>
                <Routes/>
            </Router>
            <MessageHandlerComponent/>
        </MuiPickersUtilsProvider>
    );
};

export default App;
