import React, {useEffect, useState} from 'react';
import {AppBar, Button, Grid, Toolbar, Typography} from '@material-ui/core';
import {useDispatch} from "react-redux";
import {FILE_UPLOAD, LABEL, STAR_RATING, TEXT_AREA} from "./components/TemplateTypes";
import SimplePropertyConfig from "./components/config/SimplePropertyConfig";
import TextField from "@material-ui/core/TextField";
import FeedbackTemplateRender from "./FeedbackTemplateRender";
import Paper from "@material-ui/core/Paper";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import PlusOneIcon from '@material-ui/icons/PlusOne';
import LabelConfig from "./components/config/LabelConfig";
import Popover from "@material-ui/core/Popover";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AccordionActions from "@material-ui/core/AccordionActions";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {useQuery} from "../../../hooks/useQuery";
import {deleteTemplate, loadTemplate, saveTemplateConfig} from "../../../actions/SurveyActions";
import TitleLabelConfig from "./components/config/TitleLabelConfig";
import {Prompt, useHistory} from "react-router-dom";

function getComponent(component, onChange) {
    switch (component.type) {
        case STAR_RATING:
            return <SimplePropertyConfig component={component} onChange={onChange}/>;
        case TEXT_AREA:
            return <TitleLabelConfig component={component} onChange={onChange}/>;
        case LABEL:
            return <LabelConfig component={component} onChange={onChange}/>;
        case FILE_UPLOAD:
            return <TitleLabelConfig component={component} onChange={onChange}/>;
        default:
            return <div/>
    }
}

const DEFAULT_CONFIG = {
    title: "",
    components: []
};

const FeedbackSurvey = () => {
    const _ = require('lodash');
    const dispatch = useDispatch();
    const query = useQuery();
    const templateId = query.get("templateId");
    const [title, setTitle] = useState("...");
    const [templateConfig, setTemplateConfig] = useState(DEFAULT_CONFIG);
    const [originalTemplateConfig, setOriginalTemplateConfig] = useState(DEFAULT_CONFIG);

    useEffect(() => {
        dispatch(loadTemplate(templateId, (data) => {
            setTitle(data.name);
            setTemplateConfig(data.config ? JSON.parse(data.config) : DEFAULT_CONFIG);
            setOriginalTemplateConfig(data.config ? JSON.parse(data.config) : DEFAULT_CONFIG);
        }));
    }, [dispatch, templateId]);
    const components = templateConfig ? templateConfig.components : [];
    const [expanded, setExpanded] = React.useState(false);
    const [adding, setAdding] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    let history = useHistory();
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const componentsChanged = () => {
        return !_.isEqual(templateConfig, originalTemplateConfig)
    };

    const handleSave = () => {
        dispatch(saveTemplateConfig(templateId, templateConfig, () => {
            setTemplateConfig(DEFAULT_CONFIG);
            setOriginalTemplateConfig(DEFAULT_CONFIG);
            history.push(`/templates`);
        }));

    };

    const handleDelete = () => {
        dispatch(deleteTemplate(templateId, () => {
            history.push(`/templates`);
        }));
    };

    return (
        <div>
            <Prompt when={componentsChanged()}
                    message={() => "There are some unsaved changes. Do you want to go away?"}/>
            <Grid container spacing={3} style={{paddingTop: 10}}>
                <Grid item lg={6} md={6} xs={12}>
                    <AppBar position="static">
                        <Toolbar variant="dense">
                            <Typography variant="h6" color="inherit">
                                {`[${title}] Configuration View`}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Paper style={{padding: 10}}>
                        <Accordion expanded={expanded === 'title'} onChange={handleChange('title')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{color: 'blue'}}/>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Title</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField value={templateConfig ? templateConfig.title : ''} fullWidth
                                           onChange={e => setTemplateConfig({
                                               ...templateConfig,
                                               title: e.target.value
                                           })}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'header'} onChange={handleChange('header')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{color: 'blue'}}/>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Header</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField value={templateConfig ? templateConfig.header : ''} fullWidth
                                           onChange={e => setTemplateConfig({
                                               ...templateConfig,
                                               header: e.target.value
                                           })}/>
                            </AccordionDetails>
                        </Accordion>
                        {components.map((c, idx) =>
                            <Accordion key={idx} expanded={expanded === idx} onChange={handleChange(idx)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{color: 'blue'}}/>}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>{idx + 1}:</Typography>
                                    <Typography>{c.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>{
                                    getComponent(c, (updatedComponent) => {
                                        components.splice(idx, 1, updatedComponent);
                                        setTemplateConfig({...templateConfig, components: [...components]});
                                    })}
                                </AccordionDetails>
                                <Divider/>
                                <AccordionActions>
                                    <IconButton color={"secondary"} onClick={() => {
                                        components.splice(idx, 1);
                                        setTemplateConfig({...templateConfig, components: [...components]});
                                    }}>
                                        <DeleteForeverIcon/>
                                    </IconButton>
                                </AccordionActions>
                            </Accordion>)}
                        <Accordion expanded={expanded === 'footer'} onChange={handleChange('footer')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{color: 'blue'}}/>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>Footer</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField value={templateConfig ? templateConfig.footer : ''} fullWidth
                                           onChange={e => setTemplateConfig({
                                               ...templateConfig,
                                               footer: e.target.value
                                           })}/>
                            </AccordionDetails>
                        </Accordion>
                        <div style={{display: 'flex'}}>
                            <IconButton style={{color: 'blue'}} onClick={(e) => {
                                setAnchorEl(e.target);
                                setAdding(true)
                            }}>
                                <PlusOneIcon/>
                            </IconButton>
                            <Popover
                                open={adding}
                                anchorEl={anchorEl}
                                onClose={() => setAdding(false)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}>
                                <List component="nav">
                                    {[LABEL, STAR_RATING, TEXT_AREA, FILE_UPLOAD].map((t) => <ListItem button
                                                                                                       onClick={() => {
                                                                                                           setAdding(false);
                                                                                                           setExpanded(components.length);
                                                                                                           setTemplateConfig({
                                                                                                               ...templateConfig,
                                                                                                               components: [...components, {type: t}]
                                                                                                           });
                                                                                                       }}>
                                        <ListItemText primary={t}/>
                                    </ListItem>)}
                                </List>
                            </Popover>
                            <span style={{flex: '1 1 auto'}}/>
                            <IconButton color={"secondary"} size={"small"} onClick={handleDelete}>
                                <DeleteForeverIcon/>
                            </IconButton>
                            <Button onClick={() => history.push(`/templates`)} color="primary">
                                Cancel
                            </Button>
                            <Button color="secondary" onClick={handleSave} disabled={!componentsChanged()}>
                                Save
                            </Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <FeedbackTemplateRender {...templateConfig} activeStepProp={expanded}/>
                </Grid>
            </Grid>
        </div>);
};

export default FeedbackSurvey;