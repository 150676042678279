import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import ProductList from "./views/productList/ProductList";
import NotFound from "./layouts/NotFound";
import MainLayout from "./layouts/main/MainLayout";
import LoginView from "./views/login/LoginView";
import RouteWithLayout from "./layouts/RouteWithLayout";
import ProductLayout from "./layouts/product/ProductLayout";
import ProductActivities from "./views/product/ProductActivities";
import ProductSurveyConfiguration from "./views/product/survey/ProductSurveyConfiguration";
import ProductUsers from "./views/product/users/ProductUsers";
import TemplateList from "./views/templates/TemplateList";
import FeedbackTemplateEdit from "./views/survey/template/FeedbackTemplateEdit";
import TemplateLayout from "./layouts/template/TemplateLayout";

import SurveyLayout from "./layouts/survey/SurveyLayout";
import SurveyDashboard from "./views/dashboard/SurveyHome";
import CreateSurvey from "./views/createSurvey/CreateSurvey";
import AssignSurvey from "./views/AssignSurvey/AssignSurvey";
import RespondentsDashboard from "./views/dashboard/RespondentsHome";
import RespondentLayout from "./layouts/respondents/RespondentLayout";
import CreateRespondents from "./views/createRespondents/CreateRespondents";
import RespondentSurvey from "./views/createRespondents/RespondentSurveys";
import RenderSurveyHome from "./views/public/RenderSurveyHome";


const Routes = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/surveys"
            />
            <Route
                component={LoginView}
                exact
                path="/login"
            />
            <Route
                component={RenderSurveyHome}
                exact
                path="/public/survey/template"
            />

            <RouteWithLayout
                component={SurveyDashboard}
                menuIndex={0}
                exact
                layout={MainLayout}
                path="/surveys"
            />

            <RouteWithLayout
                component={CreateSurvey}
                menuIndex={2}
                exact
                layout={SurveyLayout}
                path="/createSurvey"
            />

            <RouteWithLayout
                component={AssignSurvey}
                menuIndex={2}
                exact
                layout={SurveyLayout}
                path="/assignSurvey"
            />
            <RouteWithLayout
                component={RespondentsDashboard}
                menuIndex={1}
                exact
                layout={MainLayout}
                path="/respondents"
            />
            <RouteWithLayout
                component={CreateRespondents}
                menuIndex={3}
                exact
                layout={RespondentLayout}
                path="/createRespondents"
            />
            <RouteWithLayout
                component={RespondentSurvey}
                menuIndex={3}
                exact
                layout={RespondentLayout}
                path="/respondents/survey"
            />

            <RouteWithLayout
                component={ProductList}
                menuIndex={0}
                exact
                layout={RespondentLayout}
                path="/all-products"
            />
            <RouteWithLayout
                component={TemplateList}
                menuIndex={1}
                exact
                layout={MainLayout}
                path="/templates"
            />
            <RouteWithLayout
                component={FeedbackTemplateEdit}
                exact
                layout={TemplateLayout}
                path="/survey/template/edit"
            />
            <RouteWithLayout
                component={ProductUsers}
                menuIndex={0}
                exact
                layout={ProductLayout}
                path="/product/users"
            />
            <RouteWithLayout
                component={ProductSurveyConfiguration}
                menuIndex={1}
                exact
                layout={ProductLayout}
                path="/product/surveys"
            />
            <RouteWithLayout
                component={ProductActivities}
                menuIndex={2}
                exact
                layout={ProductLayout}
                path="/product/activities"
            />
            {/*<RouteWithLayout*/}
            {/*  component={NotFound}*/}
            {/*  menuIndex={2}*/}
            {/*  exact*/}
            {/*  layout={MainLayout}*/}
            {/*  path="/surveys"*/}
            {/*/>*/}
            <RouteWithLayout
                component={NotFound}
                exact
                layout={MainLayout}
                path="/not-found"
            />
            {/*<Redirect to="/not-found"/>*/}
        </Switch>
    );
};

export default Routes;
