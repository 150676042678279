import React, {useState, useEffect} from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useDispatch, useSelector} from "react-redux";
import {removeQuestion, updateQuestion} from "../../actions/SurveyActions";
import ReactQuill from 'react-quill';
import {
    TEXT_AREA,
    MULTIPLE_CHOICE,
    STAR_RATING,
    SCALE_TYPE,
    FILE_UPLOAD,
    PREFERENCE_PRIORITY, 
    YES_NO,
    YES_NO_COMMENT
} from "./AnswerTypes/AnswerTypes";
import {TextAreaType} from "./AnswerTypes/TextAreaType";
import {MultipleChoice} from "./AnswerTypes/MultipleChoice";
import StarRatingType from "./AnswerTypes/StarRatingType";
import ScaleType from "./AnswerTypes/ScaleType";
import PreferencePriority from "./AnswerTypes/PreferencePriority";
import {YesNo} from "./AnswerTypes/YesNo";
import FileUpload from "./AnswerTypes/FileUpload";
import { YesNoComment } from "./AnswerTypes/YesNoComment";


const CreateQuestion = ({closeView, id, questionType}) => {

    const dispatch = useDispatch();

    const question = useSelector(state => state.survey.questions.find(q => q.id === id));

    const [editorState, setEditorState] = useState({text: question.title});

    const [answerType, setAnswerType] = useState(questionType);

    const handleAnswerType = (type) => {
        setAnswerType(type.target.value);
        let quest = question;
        quest.type = type.target.value;
        dispatch(updateQuestion(quest));
    }
    
    const handleMOAnswers = (options) => {
        let quest = question;
        quest.answers = options;
        dispatch(updateQuestion(quest));
    }

    const saveAllQuestions = () => {
        closeView();
    };

    const removeQuestions = () => {
        dispatch(removeQuestion(id));
        closeView();
    };

    //Cancel Button-----
    useEffect(() => {
    }, []);


    const onChangeEditor = (value) => {
        setEditorState({text: value});
        let quest = question;
        quest.title = value;
        dispatch(updateQuestion(quest));
    };

    const generateComponent =(questionType, onChange) => {
        switch (questionType) {
            case TEXT_AREA:
                return <TextAreaType/>;
            case MULTIPLE_CHOICE:
                return <MultipleChoice updateOptions={handleMOAnswers} isPreview={false} questionOptions={question.answers} />;
            case STAR_RATING:
                return <StarRatingType component={questionType} onChange={onChange}/>;
            case SCALE_TYPE:
                return <ScaleType/>;
            case PREFERENCE_PRIORITY:
                return <PreferencePriority updateOptions={handleMOAnswers} isPreview={false} questionOptions={question.answers}/>;
            case YES_NO:
                return <YesNo/>;
            case YES_NO_COMMENT:
                return <YesNoComment onChange={onChange}/>;
            case FILE_UPLOAD:
                return <FileUpload onChange={onChange}/>;
            default:
                return <TextAreaType/>;
        }
    }


    return (
        <div className="container card create-qus-card">
            {/* <button onClick={closeView} type="button" className="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button> */}




            <div className="card-body p-0">

            {/*<div className="card-header">*/}
            {/*    <div className="q-no">Enter the survey question below </div>*/}

            {/*</div>*/}


                <div className="q-editor">
                    <ReactQuill value={editorState.text}
                                placeholder="Enter the survey question here"
                                onChange={onChangeEditor}/>
                </div>

                <div className="card-header">
                <div className="q-no">Select answer type: </div>
                <select className="form-select form-select-sm" aria-label="Default select example"
                        onChange={handleAnswerType}>
                    <option value={TEXT_AREA}>Textarea</option>
                    <option value={MULTIPLE_CHOICE}>Multiple choice</option>
                    <option value={STAR_RATING}>Star rating</option>
                    <option value={SCALE_TYPE}>Scale type</option>
                    <option value={PREFERENCE_PRIORITY}>Preference priority</option>
                    <option value={YES_NO}>Yes No</option>
                    <option value={YES_NO_COMMENT}>Yes No Comment</option>
                    <option value={FILE_UPLOAD}>File upload</option>
                </select>

            </div>

                <div className="qus-options">
                    {generateComponent(answerType , ()=>{})}

                </div>

                

                {/* <div className="qus-done-div text-center">
                    <button className="row-cols-2 btn btn-sm btn-outline-warning">Done</button>
                </div> */}

                <div className="row-cols-2 save-btn-row">
                    <button onClick={removeQuestions} className="btn btn-warning btn-cancel"> CANCEL </button>
                    <button onClick={saveAllQuestions} className="btn btn-warning btn-save"> SAVE QUESTION</button>
                </div>

            </div>

            {/* <div>
                <div className="row-cols-2 save-btn-row">
                    <button onClick={removeQuestions} className="row-cols-2 btn btn-warning btn-sm"> CANCEL </button>
                    <button onClick={saveAllQuestions} className="row-cols-2 btn btn-warning btn-sm"> SAVE</button>
                </div>
            </div> */}

        </div>


    );
}

export default CreateQuestion;
