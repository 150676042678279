import React, {useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux'
import {loadProducts} from "../../actions/ProductActions";
import ProductCard from "./components/ProductCard";
import AddProductCard from "./components/AddProductCard";

const ProductList = () => {
    const dispatch = useDispatch();
    const products = useSelector(state => state.products);
    useEffect(() => {
        dispatch(loadProducts())
    }, [dispatch]);
    return (
        <Grid container spacing={3} style={{paddingTop: 10}}>
            {products && products.map(product => (
                <Grid item key={product.id} lg={3} md={6} xs={12}>
                    <ProductCard product={product}/>
                </Grid>))}
            <Grid item lg={3} md={6} xs={12}>
                <AddProductCard/>
            </Grid>
        </Grid>
    )
};

export default ProductList;
