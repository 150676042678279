import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {cleanProduct, loadProduct} from "../../actions/ProductActions";
import ProductMenu from "./ProductMenu";
import {useQuery} from "../../hooks/useQuery";
import NotFound from "../NotFound";

const useStyles = makeStyles({
    image: {
        width: '100%'
    },
    content: {
        height: '100%'
    }
});

const MainLayout = props => {
    const {children, menuIndex} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const query = useQuery();
    const productId = query.get("productId");
    useEffect(() => {
        dispatch(loadProduct(productId));
        return function cleanup() {
            dispatch(cleanProduct())
        };
    }, [dispatch, productId]);

    const product = useSelector(state => state.product);

    return (
        <div style={{
            paddingTop: 56,
            height: '100%',
        }}>
            <ProductMenu menuIndex={menuIndex} productId={productId}/>
            {productId ?
                <div style={{display: 'flex', width: '100%', paddingTop: 10}}>
                    <div style={{width: 250}}>
                        {product && <div style={{paddingRight: 10}}>
                    <span style={{
                        height: 100,
                        borderRadius: '5px',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <img
                            alt="Product"
                            className={classes.image}
                            src={product.customerImageUrl}
                        />
                    </span>
                            <span style={{justifyContent: 'center'}}> <Typography
                                align="center"
                                gutterBottom
                                variant="h6"
                            >
                        {product.title}
                    </Typography></span>
                        </div>}
                    </div>
                    <div style={{width: '100%'}}>
                        {children}
                    </div>
                </div>
                : <NotFound name="Product"/>}
        </div>
    )
};

MainLayout.propTypes = {
    children: PropTypes.node
};

export default MainLayout;
