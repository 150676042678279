import React from 'react';
import {Paper, Tab, Tabs} from '@material-ui/core';
import UserSurveyResultTable from "./UserSurveyResultTable";
import {download, downloadSurveyResults} from "../../../actions/SurveyActions";
import {useDispatch} from "react-redux";
import RoomIcon from '@material-ui/icons/Room';

const ProductSurveyDetails = ({productSurveyId}) => {
    const [value, setValue] = React.useState(0);
    // const [result, setResult] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const dispatch = useDispatch();

    const renderContent = () => {
        return <UserSurveyResultTable productSurveyId={productSurveyId}/>;
    };

    const handleDownload = () => {
        dispatch(downloadSurveyResults({productSurveyId}, data =>{
        }));
    }


    return (
        <Paper>
            <UserSurveyResultTable productSurveyId={productSurveyId} />
        </Paper>
    )
};

export default ProductSurveyDetails;
