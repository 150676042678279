import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {AppBar, Toolbar} from '@material-ui/core';
import UserMenu from "../UserMenu";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from "@material-ui/core/IconButton";


const TemplateMenu = () => {
    return (
        <AppBar>
            <Toolbar style={{backgroundColor: "#c9d0d1"}}>
                <RouterLink to="/">
                    <img
                        alt="Logo"
                        src="/images/complexica-logo.png"
                        height="20"
                    />
                </RouterLink>
                <RouterLink to="/templates">
                    <IconButton color="primary">
                        <ArrowBackIcon/>
                    </IconButton>
                </RouterLink>
                <span style={{flex: '1 1 auto'}}/>
                <UserMenu/>
            </Toolbar>
        </AppBar>
    );
};

export default TemplateMenu;
