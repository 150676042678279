import React, {useCallback} from "react";
import {useDropzone} from 'react-dropzone'
import {Button, Dialog, DialogActions} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {updateLeftLogo} from "../../actions/SurveyActions";

const LeftLogo = ({open, handleClose, lImage}) => {
    const dispatch = useDispatch();

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const binaryStr = reader.result;

                const base64String = btoa(String.fromCharCode(...new Uint8Array(binaryStr)));
                lImage(base64String);
                dispatch(updateLeftLogo(base64String));
                handleClose();
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const handleCloseLG = () => {
        handleClose();
    };


    return (
        <Dialog open={open} maxWidth={"lg"} aria-labelledby="form-dialog-title">
            <div className="modal-body">
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                    <div className='file-select-btn'>
                        <Button color="primary">
                        Select Files
                    </Button></div>
                </div>
            </div>
            <DialogActions>
                <Button onClick={handleCloseLG} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

}
export default LeftLogo;