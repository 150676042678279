export function openNewWindow(url, width = 400, height = 500) {
    let top = window.screen.height - height;
    top = top > 0 ? top / 2 : 0;

    let left = window.screen.width - width;
    left = left > 0 ? left / 2 : 0;

    window.open(url, 'targetWindow',
        `toolbar=no,
         top=${top},   
         left=${left},   
         scrollbars=yes,
         resizable=yes,
         width=${width},
         height=${height}`);
}