import {
    LOAD_SURVEY,
    ADD_QUESTION,
    REMOVE_QUESTION,
    UPDATE_QUESTION,
    LOAD_ALL_SURVEYS,
    LOAD_TEMPLATES,
    SET_SURVEY_TITLE,
    SET_SURVEY_NAME, SET_LEFT_LOGO, SET_RIGHT_LOGO, CLEAR_SURVEY_VIEW, SET_SURVEY_DESCRIPTION, RESET_QUESTION_ORDER
} from "./types";
import {apiAction} from "./Api";

const BASE_URL = '/api/survey';

export function getSurveys(status, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/all`,
            type: LOAD_ALL_SURVEYS,
            data: {status},
            onSuccess
        }
    );
}

export function loadSurveyResults(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/results`,
            type: "SURVEY RESULTS",
            data: params,
            onSuccess
        }
    );
}

export function downloadSurveyResults(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/download-results`,
            type: "DOWNLOAD SURVEY RESULTS",
            data: params,
            onSuccess
        }
    );
}
export function firstIndexOf(array, predicate) {
    array = array ? array : [];
    for (let i = 0; i < array.length; ++i) {
        if (predicate(array[i])) {
            return i;
        }
    }
    return -1;
}

export function findFirst(array, predicate) {
    let index = firstIndexOf(array, predicate);
    return index >= 0 ? array[index] : null;
}

export const FILE_TYPES = {
    PDF: {extension: 'pdf', MIMEType: 'application/pdf', label: 'Pdf'},
    EXCEL: {
        extension: 'xlsx',
        MIMEType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        label: 'Excel'
    },
    CSV: {extension: 'csv', MIMEType: 'text/csv', label: 'CSV'}
};

export const download = (fileName, fileType, data) => {
    // const type = findFirst(Object.values(FILE_TYPES), ({extension}) => extension == fileType);
    // const MIMEType = type ? type.MIMEType : FILE_TYPES.CSV.MIMEType;
    // const byteCharacters = atob(data);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blobObject = new Blob([byteArray], {type: MIMEType});
    // if (window.navigator.msSaveOrOpenBlob) {
    //     window.navigator.msSaveOrOpenBlob(data, fileName + '.' + fileType);
    // } else {
    //     const a = document.createElement('a');
    //     a.download = fileName + (fileType ? "." + fileType : '');
    //     const url = window.URL.createObjectURL(data);
    //     a.href = url;
    //     const clickEvent = new MouseEvent('click', {
    //         'view': window,
    //         'bubbles': true,
    //         'cancelable': true
    //     });
    //     a.dispatchEvent(clickEvent);
    // }
};

export function loadTemplates(status, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/templates`,
            type: LOAD_TEMPLATES,
            data: {status},
            onSuccess
        }
    );
}

export function submitSurveys(params, files) {
    const formData = new FormData();

    const config = {
        headers: {}
    };

    config.headers['content-type'] = 'multipart/form-data';

    formData.append("params", JSON.stringify(params));
    files.forEach(file => {
        formData.append("files", file);
    });

    return apiAction({
            url: `/api/application/survey/submit`,
            method: 'POST',
            data: formData,
            headers: config.headers,
            type: "SUBMIT SURVEY"
        }
    );
}

export function saveUserSurveyResponses(params, onSuccess) {
    return apiAction({
            url: `/api/application/survey/submit-survey`,
            method: 'POST',
            data: params,
            type: "SUBMIT SURVEY RESPONSES",
            onSuccess
        }
    );
}

export function saveTemplate(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/template/save`,
            method: 'POST',
            data: params,
            type: "SAVE TEMPLATE",
            onSuccess
        }
    );
}

export function copyTemplate(params, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/template/copy`,
            method: 'POST',
            data: params,
            type: "COPY TEMPLATE",
            onSuccess
        }
    );
}

export function loadTemplate(templateId, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/template/load`,
            data: {templateId},
            type: "LOAD TEMPLATE",
            onSuccess
        }
    );
}

export function saveTemplateConfig(templateId, config, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/template/save-config`,
            method: 'POST',
            data: {id: templateId, config: JSON.stringify(config)},
            type: "SAVE TEMPLATE CONFIG",
            onSuccess
        }
    );
}

export function saveSurvey(surveyName, surveyTitle, surveyDescription, leftLogo, rightLogo, config, count, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/template/save-survey`,
            method: 'POST',
            data: {
                surveyName: surveyName,
                surveyTitle: surveyTitle,
                surveyDescription: surveyDescription,
                leftLogo: leftLogo,
                rightLogo: rightLogo,
                questionCount: count,
                questions: JSON.stringify(config)},
            type: "SAVE TEMPLATE CONFIG",
            onSuccess
        }
    );
}

export function updateSurvey(id,surveyName, surveyTitle, surveyDescription, leftLogo, rightLogo, config, count, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/template/update-survey`,
            method: 'PUT',
            data: {surveyId: id,
                surveyName: surveyName,
                surveyTitle: surveyTitle,
                surveyDescription: surveyDescription,
                leftLogo: leftLogo,
                rightLogo: rightLogo,
                questionCount: count,
                questions: JSON.stringify(config)},
            type: "SAVE TEMPLATE CONFIG",
            onSuccess
        }
    );
}

export function deleteTemplate(templateId, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/template/delete`,
            method: 'DELETE',
            data: {templateId},
            type: "DELETE TEMPLATE",
            onSuccess
        }
    );
}

export function archiveTemplate(templateId, status, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/template/archive`,
            method: "PUT",
            data: {
                surveyId: templateId,
                status: status
            },
            type: "ARCHIVE TEMPLATE",
            onSuccess
        }
    );
}

export const clearSurvey = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SURVEY_VIEW
        });
    }
}


export const updateSurveyTitle = (sTitle) => {
    return (dispatch) => {
        dispatch({
            type: SET_SURVEY_TITLE,
            payload: sTitle
        });
    }
}

export const updateSurveyDescription = (sDescription) => {
    return (dispatch) => {
        dispatch({
            type: SET_SURVEY_DESCRIPTION,
            payload: sDescription
        });
    }
}

export const updateSurveyName = (sName) => {
    return (dispatch) => {
        dispatch({
            type: SET_SURVEY_NAME,
            payload: sName
        });
    }
}

export const updateLeftLogo = (lLogo) => {
    return (dispatch) => {
        dispatch({
            type: SET_LEFT_LOGO,
            payload: lLogo
        });
    }
}

export const updateRightLogo = (rLogo) => {
    return (dispatch) => {
        dispatch({
            type: SET_RIGHT_LOGO,
            payload: rLogo
        });
    }
}

export const addNewQuestion = (question) => {
    return (dispatch) => {
        dispatch({
            type: ADD_QUESTION,
            payload: question
        });
    }
}

export const removeQuestion = (id) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_QUESTION,
            payload: {
                id: id
            }
        });
    }
}

export const updateQuestion = (question) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_QUESTION,
            payload: question
        });
    }
}

export const resetQuestionOrder = (questionList) => {
    return (dispatch) => {
        dispatch({
            type: RESET_QUESTION_ORDER,
            payload: questionList
        });
    }
}

export function loadSurvey(templateId, onSuccess) {
    return apiAction({
            url: `${BASE_URL}/surveys/get-survey`,
            data: {templateId},
            type: LOAD_SURVEY,
            onSuccess
        }
    );
}



