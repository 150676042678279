import React, {useEffect, useState} from "react";
import {TextField} from '@material-ui/core';
import SurveySelect from "../../components/SurveySelect";
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";
import {useDispatch} from "react-redux";
import {getAllUserGroups, scheduleProductSurvey} from "../../actions/ProductActions";
import GroupSelect from "../../components/GroupSelect";
import {useQuery} from "../../hooks/useQuery";
import {useHistory} from "react-router-dom";
import {alertInfo} from "../../reducers/MessageReducer";


const AssignSurvey = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useQuery();
    const templateId = query.get("templateId");

    const [surveyId, setSurveyId] = useState(templateId);
    const [groupId, setGroupId] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(moment(new Date()).add(7, 'days'));
    const [notification, setNotification] = useState('3');
    const [errorGroupSelMsg, setErrorGroupSelMsg] = React.useState("")

    useEffect(() => {
        dispatch(getAllUserGroups())
    }, [dispatch]);

    const changeStartDate = (date) => {
        if (date > endDate) {
            setEndDate(moment(date).add(7, 'days'))
        }
        setStartDate(date);
    };

    const handleClose = () => {
        history.push(`/surveys`);
    };


    const handleSave = () => {
        if(groupId !== null){
            dispatch(scheduleProductSurvey({
                groupId: groupId,
                startDate: startDate,
                endDate: endDate,
                surveyId: surveyId,
                notification: notification
            }, handleClose));
            dispatch(alertInfo('Submitted'));
        }else{
            setErrorGroupSelMsg("Select respondent group");
        }

    };

    const setGroup = (val) =>{
        setGroupId(val)
        setErrorGroupSelMsg('');
    }

    return (
        <div className="container as-sur-container">
            <div className="card main-crd">

                <div className="main-header"><h4>ASSIGN SURVEY TO RESPONDENT GROUP(S)</h4></div>

                <div className="card sel-survey-card">
                    <div className="card-body">
                        <div className="row">
                            <h5>Select Survey</h5>
                            <div className="dropdown">
                                <SurveySelect value={surveyId} onChange={setSurveyId}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card usr-group-card">
                    <div className="card-header">
                        Available Respondent Group
                    </div>
                    <div className="card-body">
                        <GroupSelect value={groupId} onChange={setGroup}/>
                        <br/>
                        {errorGroupSelMsg !== "" ? <div className="groupErr">{errorGroupSelMsg}</div> : ""}
                    </div>
                </div>

                <div className="card sch-survey-card">
                    <div className="card-header">
                        Schedule Survey
                    </div>
                    <div className="card-body">
                        <div className="row-cols">
                            <div>
                                <KeyboardDatePicker
                                    style={{width: "100%"}}
                                    disableToolbar
                                    clearable
                                    format="DD/MM/YYYY"
                                    id="date-picker-inline"
                                    label="Start Date"
                                    value={startDate}
                                    onChange={changeStartDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardDatePicker
                                    style={{width: "100%"}}
                                    disableToolbar
                                    clearable
                                    format="DD/MM/YYYY"
                                    id="date-picker-inline"
                                    label="End Date"
                                    value={endDate}
                                    minDate={startDate}
                                    onChange={setEndDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="standard-full-width"
                                    label={"No. of reminders"}
                                    fullWidth
                                    value={notification}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => {
                                        setNotification(e.target.value);
                                    }}
                                    required/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row sub-btn">
                    <button className="row-cols-2 btn btn-outline-warning btn-snd-survey" onClick={handleSave}> SEND
                        SURVEY
                    </button>
                </div>


            </div>

        </div>

    )

}
export default AssignSurvey;
