import React from 'react';
import {useDispatch, useSelector} from 'react-redux'
import Button from "@material-ui/core/Button";
// import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {apiError} from "../actions/Api";
import {MuiThemeProvider} from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles'
import {alertClose} from "../reducers/MessageReducer";

const theme = createTheme({
    overrides: {
        MuiAlert: {
            message: {
                display: "flex",
                padding: 8,
                justifyContent: "center",
                alignItems: "start",
                alignContent: "center",
                flexDirection: "column"
            }
        },
        MuiButton: {
            textSizeSmall: {
                marginTop: 5,
                padding: 4,
                fontSize: "0.7rem"
            }
        },
        'MuiSnackbar-root': {
            alignContent: "center"
        }
    }
});

const MessageHandlerComponent = () => {

    const dispatch = useDispatch();

    // const [showDetails, setShowDetails] = React.useState(true);

    const toggleSwitch = () => {
        // setShowDetails(!showDetails);
        dispatch(alertClose());
    };

    const context = useSelector(state => state.message);

    const message = (context || {}).message;

    const details = (context || {}).details;

    let isOpen = true;

    if (!context || !context.message) {
        return null;
    }

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props}/>;
    };

    const ShowDetailsButton = () => {
        return (
            <Button color="inherit" size="small" variant="text" style={{ color: "white", border: "1px solid white" }} onClick={toggleSwitch}>
                { context.isShow ? "Hide Details" : "Show Details" }
            </Button>
        );
    }

    const handleClose = () => {
        dispatch(alertClose());
        // dispatch(apiError(null));
        // isOpen = false;
    };

    return (
        <MuiThemeProvider theme={theme}>
            <Snackbar open={context.isShow} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={context.type}>
                    { message }
                    <br/>{ context.isShow ? details : null }<br/>
                    { details ? <ShowDetailsButton/> : null }
                </Alert>
            </Snackbar>
        </MuiThemeProvider>
    )
}

export default MessageHandlerComponent;