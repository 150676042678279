import React, {useEffect} from "react";
import userGroupImg from "../../assert/images/userGroup.svg"
import {useHistory} from "react-router-dom";
import RespondentsList from "./RespondentsList";
import {useDispatch, useSelector} from "react-redux";
import {getAllUserGroups, loadProducts} from "../../actions/ProductActions";
import CardContent from "@material-ui/core/CardContent";


const RespondentsHome =() =>{
  const history = useHistory();
  const dispatch = useDispatch();
  // const products = useSelector(state => state.products);
  // useEffect(() => {
  //   dispatch(loadProducts())
  // }, [dispatch]);

  const groups = useSelector(state => state.userGroup);
  useEffect(() => {
    dispatch(getAllUserGroups())
  }, [dispatch]);


  return<>{groups.length === 0 ?
      <div>
        <div className="survey-home-container container">
          <div className="survey-card p-4">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="survey-home-card-header">NO RESPONDENTS GROUPS CREATED</h2>
              </div>
            </div>

            <CardContent>
              <div className="row survey-home-item">
                <div className="card-img-top ">
                  <img alt="create-survey-img" src={userGroupImg}/>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 btn-create">
                  <button className="btn btn-primary" onClick={() => history.push(`/createRespondents`)}> Create</button>
                </div>
              </div>

            </CardContent>


          </div>
        </div>
      </div>
    :
    <RespondentsList/>

  }</>;
}
export default RespondentsHome;
