import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import SurveySelect from "../../../components/SurveySelect";
import {useDispatch} from "react-redux";
import moment from "moment";
import {
    scheduleProductSurvey,
    updateScheduleProductSurvey
} from "../../../actions/ProductActions";
import {alertInfo} from "../../../reducers/MessageReducer";

const ScheduleSurveyDialog = ({open, groupId, productSurvey}) => {
    const [name, setName] = useState(3);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(moment(new Date()).add(7, 'days'));
    const [surveyId, setSurveyId] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if(productSurvey !== null){
            setSurveyId(productSurvey.surveyId);
            setStartDate(productSurvey.startDate);
            setEndDate(productSurvey.endDate);
            setName(productSurvey.notificationCount);
        }
    }, [productSurvey]);

    const changeStartDate = (date) => {
        if (date > endDate) {
            setEndDate(moment(date).add(7, 'days'))
        }
        setStartDate(date);
    };

    const handleClose = () => {
        window.location.reload();
    };


    const handleSave = () => {
        dispatch(scheduleProductSurvey({groupId: groupId,
            startDate: startDate,
            endDate: endDate,
            surveyId: surveyId,
            notification: name}, handleClose));
        dispatch(alertInfo('Submitted'));
    };

    const handleEdit = (productSurveyId) => {
        console.log("edit {}", productSurveyId);
        dispatch(updateScheduleProductSurvey({groupId: groupId,
            productSurveyId: productSurveyId,
            startDate: startDate,
            endDate: endDate,
            surveyId: surveyId,
            notification: name}, handleClose));
        dispatch(alertInfo('Submitted'));
    };


    return (<Dialog open={open} onClose={handleClose} maxWidth={"xs"} aria-labelledby="form-dialog-title">
            <DialogTitle>SCHEDULE A SURVEY</DialogTitle>
            <DialogContent>
                <SurveySelect value={surveyId} onChange={setSurveyId}/>
                <KeyboardDatePicker
                    style={{width: "100%"}}
                    disableToolbar
                    clearable
                    format="DD/MM/YYYY"
                    id="date-picker-inline"
                    label="Start Date"
                    value={startDate}
                    onChange={changeStartDate}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    style={{width: "100%"}}
                    disableToolbar
                    clearable
                    format="DD/MM/YYYY"
                    id="date-picker-inline"
                    label="End Date"
                    value={endDate}
                    minDate={startDate}
                    onChange={setEndDate}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <TextField
                    id="standard-full-width"
                    label={"No. of reminders"}
                    fullWidth
                    value={name}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={e => {
                        setName(e.target.value);
                    }}
                    required
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                { productSurvey !== null && productSurvey.id !== surveyId ?
                    <Button color="secondary" onClick={()=>handleEdit(productSurvey.id)} disabled={ !name || !surveyId}>
                        EDIT INFO
                    </Button>
                    :
                    <Button color="secondary" onClick={handleSave} disabled={ !name || !surveyId}>
                        SEND SURVEY
                    </Button>
                }

            </DialogActions>
        </Dialog>
    )
};

export default ScheduleSurveyDialog;
