import React from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {AppBar, Tab, Tabs, Toolbar} from '@material-ui/core';
import UserMenu from "../UserMenu";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from "@material-ui/core/IconButton";


const ProductMenu = ({menuIndex, productId}) => {
    const history = useHistory();
    return (
        <AppBar>
            <Toolbar style={{backgroundColor: "#c9d0d1"}}>
                <RouterLink to="/">
                    <img
                        alt="Logo"
                        src="/images/complexica-logo.png"
                        height="20"
                    />
                </RouterLink>
                <RouterLink to="/">
                    <IconButton color="primary">
                        <ArrowBackIcon/>
                    </IconButton>
                </RouterLink>
                <Tabs
                    value={menuIndex}
                    centered
                    scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Users" onClick={() => history.push(`/product/users?productId=${productId}`)}/>
                    <Tab label="Surveys" onClick={() => history.push(`/product/surveys?productId=${productId}`)}/>
                    <Tab label="Activities" onClick={() => history.push(`/product/activities?productId=${productId}`)}/>
                </Tabs>
                <span style={{flex: '1 1 auto'}}/>
                <UserMenu/>
            </Toolbar>
        </AppBar>
    );
};

export default ProductMenu;
