import React from 'react';
import {Box, TextareaAutosize, Typography} from '@material-ui/core';


const TextAreaBox = ({label, onChange, value=""}) => {
    return (
        <Box mb={3} borderColor="transparent">
            <Typography component="legend">{label}</Typography>
            <TextareaAutosize style = {{width:275}} value={value} rowsMin={5} onChange={e => onChange(e.target.value)}/>
        </Box>
    );
};

export default TextAreaBox;