export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const API_ERROR = 'API_ERROR';
export const API_INFO = 'API_INFO';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const CLEAN_PRODUCT = 'CLEAN_PRODUCT';
export const LOAD_PRODUCT_ACTIVITIES = 'LOAD_PRODUCT_ACTIVITIES';
export const LOAD_PRODUCT_USERS = 'LOAD_PRODUCT_USERS';
export const LOAD_ALL_SURVEYS = 'LOAD_ALL_SURVEYS';
export const SCHEDULE_PRODUCT_SURVEY = 'SCHEDULE_PRODUCT_SURVEY';
export const LOAD_PRODUCT_SURVEYS = 'LOAD_PRODUCT_SURVEYS';
export const LOAD_PRODUCT_USER_SURVEYS = 'LOAD_PRODUCT_USER_SURVEYS';
export const ASSIGN_PRODUCT_SURVEY_TO_USER = 'ASSIGN_PRODUCT_SURVEY_TO_USER';
export const LOAD_PRODUCT_USER_GROUPS = 'LOAD_PRODUCT_USER_GROUPS';
export const NEW_PRODUCT_USER_GROUP = 'NEW_PRODUCT_USER_GROUP';
export const CHANGE_PRODUCT_USER_GROUP = 'CHANGE_PRODUCT_USER_GROUP';
export const LOAD_ALL_CUSTOMERS = 'LOAD_ALL_CUSTOMERS';
export const SAVE_PRODUCT = 'SAVE_PRODUCT';
export const LOAD_TEMPLATES = 'LOAD_TEMPLATES';
export const SET_SURVEY_TITLE = 'SET_SURVEY_TITLE';
export const SET_SURVEY_DESCRIPTION = 'SET_SURVEY_DESCRIPTION';
export const SET_SURVEY_NAME = 'SET_SURVEY_NAME';
export const ADD_QUESTION = 'ADD_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const RESET_QUESTION_ORDER = 'RESET_QUESTION_ORDER';
export const LOAD_SURVEY = 'LOAD_SURVEY';
export const CLEAR_SURVEY_VIEW = 'CLEAR_SURVEY_VIEW';
export const LOAD_USER_GROUPS = 'LOAD_USER_GROUPS';
export const LOAD_USER_GROUP = 'LOAD_USER_GROUP';
export const SET_LEFT_LOGO = 'SET_LEFT_LOGO';
export const SET_RIGHT_LOGO = 'SET_RIGHT_LOGO';
export const ALERT_INFO = 'ALERT_INFO';
export const ALERT_CLOSE = 'ALERT_CLOSE';
export const LOAD_GROUP_USERS = 'LOAD_GROUP_USERS';

