import {Avatar, Dialog, DialogTitle, List, ListItem} from "@material-ui/core";
import React from "react";



const RespondentsDialog =({ open, onClose, groupUsers }) => {

    const handleClose = () => {
        onClose(false);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Respondents List</DialogTitle>
            <List sx={{ pt: 0 }}>
                {groupUsers.map((user,index) => (
                    <ListItem button key={index}>
                        <Avatar/>&nbsp;{user}
                    </ListItem>
                ))}
            </List>
        </Dialog>
    )
}
export default RespondentsDialog;