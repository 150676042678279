import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/styles';
import {Card, CardActionArea, CardActions, CardContent, Divider, Grid, Typography} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import EditProductCard from "./EditProductCard";
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    root: {},
    imageContainer: {
        height: 100,
        width: 100,
        margin: '0 auto',
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    description: {},
    image: {
        width: '100%'
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center'
    },
    statsIcon: {
        color: 'gray',
        marginRight: 5
    }
});

function getDevice(type) {
    switch (type) {
        case 'tablet':
            return <TabletMacIcon/>;
        default:
            return <LaptopMacIcon/>
    }
}

const ProductCard = props => {
    const [editing, setEditing] = React.useState(false);
    const {className, product, ...rest} = props;
    let history = useHistory();
    const classes = useStyles();
    return <>{editing ? <EditProductCard onCancel={() => setEditing(false)} product={product}/> :
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardActionArea style={{height: 310}}>
                <CardContent>
                    <div className={classes.imageContainer}>
                        <img
                            alt="Product"
                            className={classes.image}
                            src={product.customerImageUrl}
                        />
                    </div>
                    <Typography
                        align="center"
                        gutterBottom
                        variant="h6"
                    >
                        {product.title}
                    </Typography>
                    <Typography
                        variant="body1"
                    >
                        {product.details}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <Divider/>
            <CardActions>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid className={classes.statsItem} item>
                        <Button size="small" color="primary" onClick={() => {
                            setEditing(true)
                        }}>
                            <EditIcon fontSize="small"/> Edit
                        </Button>
                    </Grid>
                    <Grid className={classes.statsItem} item>
                        <span className={classes.statsIcon}>{getDevice(product.type)}</span>
                        <Typography
                            display="inline"
                            variant="body2"
                        >
                            {`${product.totalUsers} User${product.totalUsers === 1 ? '' : 's'}`}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    }</>;
};

ProductCard.propTypes = {
    className: PropTypes.string,
    product: PropTypes.object.isRequired
};

export default ProductCard;
