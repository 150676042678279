import React, {useState} from 'react';
// import {Box, TextareaAutosize} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

export const TextAreaType = ({onChange}) => {

    const [textValue, setTextValue] = useState("");
    const [error, setError] = useState(false);
    const handleEnteredVal =(val) =>  {
        let test = (/[a-z A-Z]/.test(val.trim()) ? false : true);
        if(test){
            setError(true)
            setTextValue("Incorrect entry")
        }else{
            setError(false)
            setTextValue("")
            onChange(val);
        }
    }

    return (
        <TextField fullWidth label="" id="fullWidth" onChange={e => handleEnteredVal(e.target.value)}
                   error ={error} helperText={textValue}/>
    );
}