import * as React from 'react';
import {Grid, MenuItem, Select} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@mui/icons-material/Add';
import {Stack} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import {useState} from "react";
import {Autocomplete} from "@material-ui/lab";


const PreferencePriority = ({updateOptions, questionOptions, isPreview, onChange}) => {
    const [options, setOptions] = React.useState(questionOptions);
    const [textValue, setTextValue] = useState("");


    const handleOptionAdd = () => {
        if (!isPreview) {
            if (textValue.trim().length === 0) return;
            setTextValue("");
            setOptions([...options, {label: textValue}]);
            updateOptions([...options, {label: textValue}]);
        }
    };
    
    const handleChangeValue = (event,  values) => {
        const result = values.map(({ label }) => label).join(', ');
        onChange(result);
    }

    const changeOptionValue = (val) => {
        if (!isPreview) {
            setTextValue(val.target.value);
        }
    };

    const handleOptionRemove = (index) => {
        if (!isPreview) {
            let optionArray = options;
            optionArray = optionArray.filter(a => a !== optionArray[index]);
            setOptions(optionArray);
            updateOptions(optionArray);
        }
    };


    return (
        <Grid>

            {!isPreview ? (
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField fullWidth label="" id="priorityVal" value={textValue} onChange={changeOptionValue} label="Add priority options"/>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack direction="row" paddingTop={3}>
                            <IconButton aria-label="add" size="small" onClick={handleOptionAdd}>
                                <AddIcon fontSize="inherit"/>
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>) : ""}


            {questionOptions.map((option, index) => (
                <Grid key={index}>
                    {!isPreview ? (
                        <Grid container spacing={2} alignItems="center" key={index}>
                    <Grid item xs={8}>
                        <TextField fullWidth value={option.label} key={index} />
                    </Grid>
                        <Grid item xs={2}>
                            <IconButton aria-label="remove" size="small" onClick={() => handleOptionRemove(index)}>
                                <RemoveIcon fontSize="inherit" />
                            </IconButton>
                        </Grid></Grid>) : ""}
                </Grid>
            ))}

            {isPreview ? (
            <Grid container spacing={1}>
                <Grid item xs={10}>
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={questionOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                {...params}
                                variant="standard"
                                label="Select options in priority order"
                            />
                        )}
                        onChange= {handleChangeValue}
                    />
                </Grid>
            </Grid>
            ): ""}


        </Grid>

    );

}
export default PreferencePriority;
