import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    formControl: {
        width: "100%"
    },
    imageContainer: {
        height: 16,
        width: 16,
        marginRight: 10,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%'
    },
    row: {
        display: 'flex'
    }
});

const ProductSelect = ({products, value, onChange}) => {
    const classes = useStyles();


    return (
        <FormControl className={classes.formControl} required>
            <InputLabel id="customer-select-label">Application</InputLabel>
            <Select
                labelId="customer-select-label"
                id="customer-select"
                value={value || ''}
                onChange={e => onChange(e.target.value)}
            >
                {products.map((e => (
                    <MenuItem key={e.id} value={e.id}>
                    <div className={classes.row}>

                        <span>
                        {e.title}
                        </span>
                    </div>
                </MenuItem>)))}
            </Select>
        </FormControl>
    );
}

export default ProductSelect;