// import React from "react";
import axios from "axios";
import _ from 'lodash'
import {apiEnd, apiError, apiInfo, apiStart} from "./actions/Api";
import {API} from "./actions/types";
import {logout} from "./actions/UserActions";
import {alertError, alertInfo} from "./reducers/MessageReducer";


// eslint-disable-next-line import/no-anonymous-default-export
export default ({dispatch}) => next => action => {

    next(action);

    if (action.type !== API) return;
    const {
        url,
        method,
        data,
        onSuccess,
        onFailure,
        type,
        headers,
        responseType
    } = action.payload;

    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    if (type) {
        dispatch(apiStart(type));
    }

    axios
        .request({
            url,
            method,
            headers,
            responseType,
            [dataOrParams]: data,
            // paramsSerializer: (() => {
            //     return Object.keys(_.omitBy(data, _.isNil)).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
            // })
        })
        .then(({data}) => {
            dispatch({type, payload: data});
            onSuccess(data);
            if (data.message && data.message.length) {
                dispatch(alertInfo(data.message))
            }
        })
        .catch(error => {
            // console.log(error.response.data.message);
            dispatch(alertError(error.response.data.message));
            // dispatch(apiError(error.response ? error.response.data : { message: error.message, type: error.type }));
            onFailure();

            if (error.response && error.response.status === 401) {
                dispatch(logout());
            }
        })
        .finally(() => {
            if (type) {
                dispatch(apiEnd(type));
            }
        });
};
