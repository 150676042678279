import React, {useEffect, useState} from 'react';
import {AppBar, Button, Step, StepContent, StepLabel, Stepper, Toolbar, Typography} from '@material-ui/core';
import {FILE_UPLOAD, LABEL, STAR_RATING, TEXT_AREA} from "./components/TemplateTypes";
import StarRating from "./components/StarRating";
import TextAreaBox from "./components/TextAreaBox";
import LabelBox from "./components/LabelBox";
import DropzoneComponent from "./components/DropzoneComponent";


function getComponent(component, value, setData) {
    switch (component.type) {
        case STAR_RATING:
            return <StarRating {...component} value={value}
                               onChange={(value) => setData({[component.propertyName]: value})}/>;
        case TEXT_AREA:
            return <TextAreaBox {...component} value={value}
                                onChange={(value) => setData({[component.propertyName]: value})}/>;
        case LABEL:
            return <LabelBox  {...component} />;
        case FILE_UPLOAD:
            return <DropzoneComponent {...component} onChange={(value) => setData({[component.propertyName]: value})}/>
        default:
            return <div/>
    }
}

const FeedbackTemplateRender = ({components = [], onSubmit, title, header, footer, activeStepProp = 0}) => {
    const [activeStep, setActiveStep] = useState(activeStepProp);
    const [data, setData] = useState({});

    useEffect(() => {
        setActiveStep(activeStepProp)
    }, [activeStepProp]);

    const handleNext = (isSubmit) => {
        if (isSubmit) {
            onSubmit && onSubmit(data);
            setTimeout(() => window.close(), 3000);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (<div style={{width: 380, margin: 'auto'}}>
        <AppBar position="static">
            <Toolbar variant="dense">
                <Typography variant="h6" color="inherit">
                    {title}
                </Typography>
            </Toolbar>
        </AppBar>
        {header &&
        <Typography style={{padding: 10, backgroundColor: 'white'}}>
            {header}
        </Typography>
        }
        <Stepper activeStep={activeStep} orientation="vertical">
            {components.map((component, idx) => (
                <Step key={idx}>
                    <StepLabel>{component.title}</StepLabel>
                    <StepContent>
                        {getComponent(component, data[component.propertyName], (part) => {
                            setData({...data, ...part});
                        })}
                        <div style={{marginTop: 20}}>
                            <Button
                                size="small"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleNext(activeStep === components.length - 1)
                                }}
                            >
                                {activeStep === components.length - 1 ? 'Submit' : 'Next'}
                            </Button>
                        </div>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
        {footer &&
        <Typography variant="subtitle2" style={{padding: 10, backgroundColor: 'white'}}>
            {footer}
        </Typography>
        }
    </div>);
};

export default FeedbackTemplateRender;