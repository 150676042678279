import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import ProductReducer from "./ProductReducer";
import ProductsReducer from "./ProductsReducer";
import AuthReducer from "./AuthReducer";
import TemplateReducer from "./TemplateReducer";
import MessageReducer from "./MessageReducer";
import SurveyReducer from "./SureveyReducer";
import UserGroupReducer from "./UserGroupReducer";


export default combineReducers({
    auth: AuthReducer,
    product: ProductReducer,
    products: ProductsReducer,
    templates: TemplateReducer,
    message: MessageReducer,
    survey: SurveyReducer,
    userGroup: UserGroupReducer,
    form: formReducer
});