
import React from "react";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';


const UsersTable = ({users, setCheckedUsers, filter, checkedUsers}) => {

    const rows = [...users];
    const numRowsDefault = 25;
    const [pageSize, setPageSize] = React.useState(numRowsDefault);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const rowSelectionHandler = (selection) => {
        setSelectedUsers(selection);
        setCheckedUsers(selection);
    }

    const columns = [
        {field: 'firstName', headerName: 'First Name', width: 150, editable: true },
        {field: 'lastName', headerName: 'Last Name', width: 150, editable: true },
        {field: 'email', headerName: 'Email', width: 350, editable: true },
    ];

    React.useEffect(() => {
        setLoading(false);
        setSelectedUsers(checkedUsers);
        
    },[checkedUsers]);
    

    return (
    <>
        <hr/>
        <Box sx={{ height: 800, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[numRowsDefault, 50, 100]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                checkboxSelection
                onSelectionModelChange={rowSelectionHandler}
                selectionModel={selectedUsers}
                loading={loading}
            />
        </Box>
    </>
    )

}
export default UsersTable;