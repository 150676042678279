import React, {useCallback, useEffect, useState} from "react";
import {CardContent} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {Add, Delete, Edit} from "@material-ui/icons";
import CreateQuestion from "./CreateQuestion";
import {
    addNewQuestion,
    removeQuestion, resetQuestionOrder,
    saveSurvey, updateQuestion,
    updateSurvey, updateSurveyDescription, updateSurveyName,
    updateSurveyTitle
} from "../../actions/SurveyActions";
import {useDispatch, useSelector} from "react-redux";
import {TextAreaType} from "./AnswerTypes/TextAreaType";

import parse from 'html-react-parser'
import IconButton from "@material-ui/core/IconButton";
import {
    FILE_UPLOAD,
    MULTIPLE_CHOICE,
    PREFERENCE_PRIORITY,
    SCALE_TYPE,
    STAR_RATING,
    TEXT_AREA,
    YES_NO,
    YES_NO_COMMENT
} from "./AnswerTypes/AnswerTypes";
import {MultipleChoice} from "./AnswerTypes/MultipleChoice";
import StarRatingType from "./AnswerTypes/StarRatingType";
import ScaleType from "./AnswerTypes/ScaleType";
import ReactQuill from 'react-quill';
import SaveSurveyDialog from "./SaveSurveyDialog";
import LeftLogo from "./LeftLogo";
import RightLogo from "./RightLogo";
import ScheduleSurveyDialog from "../product/survey/ScheduleSurveyDialog";
import QuestionCreateDialog from "./QuestionEditDialog";
import QuestionEditDialog from "./QuestionEditDialog";
import PreferencePriority from "./AnswerTypes/PreferencePriority";

import ReactDOM from "react-dom";
import Example from "./example";
import { DndProvider } from "react-dnd";
import Backend, {HTML5Backend} from "react-dnd-html5-backend";
import Card from "./Card";
import update from "immutability-helper";
import {YesNo} from "./AnswerTypes/YesNo";
import FileUpload from "./AnswerTypes/FileUpload";
import { YesNoComment } from "./AnswerTypes/YesNoComment";

//----Answer Type Generate
function generateComponent(questionType, options, questionNo) {
    switch (questionType) {
        case TEXT_AREA:
            return <TextAreaType/>;
        case MULTIPLE_CHOICE:
            return <MultipleChoice updateOptions={null} isPreview={true} questionOptions={options} questionId={questionNo}/>;
        case STAR_RATING:
            return <StarRatingType/>;
        case SCALE_TYPE:
            return <ScaleType/>;
        case PREFERENCE_PRIORITY:
            return <PreferencePriority updateOptions={null} isPreview={true} questionOptions={options} questionId={questionNo}/>;
        case YES_NO:
            return <YesNo/>;
        case YES_NO_COMMENT:
            return <YesNoComment/>;
        case FILE_UPLOAD:
            return <FileUpload/>;
        default:
            return <TextAreaType/>;
    }
}

const AddQuestion = ({status}) => {
    const dispatch = useDispatch();


    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openLeftLogo, setOpenLeftLogo] = useState(false);
    const [openRightLogo, setOpenRightLogo] = useState(false);

    const [newQuestionView, setNewQuestionView] = React.useState(false);
    const [newQuestionId, setNewQuestionId] = React.useState(null)

    const [questions, setQuestion] = React.useState({questionsList: []});
    const [errorTitleMsg, setErrorTitleMsg] = React.useState("");

    const [editQuestionView, setEditQuestionView] = React.useState(false);



    const addNewQuestions = (newQuestions) => {
        setQuestion({...questions, questionsList: questions.questionsList.concat(newQuestions)});
        setNewQuestionView(false);
    };

    const showNewQuestionView = () => {
        let id = (new Date()).getTime();
        setNewQuestionId(id);
        dispatch(addNewQuestion({
            id: id,
            type: TEXT_AREA,
            title: '',
            answers: []
        }));
        setNewQuestionView(true)
    }

    const removeNewQuestionView = () => {
        setNewQuestionView(false)
    }

    const handleLeftLogo = () => {
        setOpenLeftLogo(true);
    }

    const handleRightLogo = () => {
        setOpenRightLogo(true);
    }

    //------Get Saved Survey
    const survey = useSelector(state => state.survey);

    const [editorState, setEditorState] = useState({text: survey.surveyTitle ? survey.surveyTitle : ''});
    const [descriptionState, setDescriptionState] = useState({text: survey.surveyDescription ? survey.surveyDescription : ''});
    const [leftImage, setLeftImage] = React.useState(survey.leftLogo ? survey.leftLogo : '');
    const [rightImage, setRightImage] = React.useState(survey.rightLogo ? survey.rightLogo : '');
    const [cards, setCards] = React.useState([]);
    const [isUpdate, setIsUpdate] = React.useState(false);

    //----Save Survey
    const handleSave = () => {
        if (survey.surveyName.trim() !== "") {
            if (survey.surveyId === 0) {
                dispatch(saveSurvey(survey.surveyName,
                    survey.surveyTitle,
                    survey.surveyDescription,
                    survey.leftLogo,
                    survey.rightLogo,
                    cards,
                    survey.questions.length,
                    setOpenSaveDialog(true)));
                // () => {
                //     history.push(`/surveys`);
                // }));
            } else {

                dispatch(updateSurvey(survey.surveyId,
                    survey.surveyName,
                    survey.surveyTitle,
                    survey.surveyDescription,
                    survey.leftLogo,
                    survey.rightLogo,
                    cards,
                    survey.questions.length,
                    setOpenSaveDialog(true)));
                // () => {
                //     history.push(`/surveys`);
                // }));

            }
        } else {
            setErrorTitleMsg("Title Required");
        }

    };


    //----Change Survey Title State
    const handleSTitle = (value) => {
        setEditorState({text: value});
        dispatch(updateSurveyTitle(value))
    }

    //----Change Survey Name State
    const handleSName = (evt) => {
        setErrorTitleMsg("");
        dispatch(updateSurveyName(evt.target.value))
    }

    //----Change Survey Title State
    const handleSDescription = (value) => {
        setDescriptionState({text: value});
        dispatch(updateSurveyDescription(value))
    }

    const getQuestions = () => {
        return survey.questions ?
            survey.questions.filter((q, i) => !newQuestionView || (survey.questions.length - 1) !== i)
            : [];
    }

    useEffect(() => {
        if(isUpdate){
            dispatch(resetQuestionOrder(cards));
            setIsUpdate(false);
        }
    }, [isUpdate]);

    useEffect(() => {
        setCards([...survey.questions]);
    }, [survey.questions]);

    const editQuestion = useCallback((id) => {
        setNewQuestionId(id);
        setEditQuestionView(true);
    });

    const deleteQuestion = useCallback((id) => {
        dispatch(removeQuestion(id));
        setNewQuestionView(false)
    });

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = cards[dragIndex]
            setCards(
                update(cards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            )
            setIsUpdate(true)
        },
        [cards],)


    const renderCard = (question, index) => {
        return (
            <Card
                key={index+1}
                index={index}
                id={question.id}
                question={parse(question.title)}
                answerType={generateComponent(question.type, question.answers)}
                moveCard={moveCard}
                editQuestion={editQuestion}
                deleteQuestion={deleteQuestion}
            />
        )
    }


    return (
        <div className="container-lg">
            <CardContent>
                <div className="save-btn-div p-1">
                    <div className="sur-name-div">
                        <TextField className="sur-name-field" label="Survey name to save as" onChange={handleSName}
                                                        defaultValue={survey.surveyName}/>
                        <br/>
                        {errorTitleMsg !== "" ? <div className="code-val">{errorTitleMsg}</div> : ""}
                    </div>


                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <button className="btn btn-warning btn-sm" onClick={handleSave}> SAVE SURVEY</button>
                    </div>

                </div>


                <div className="title-row">
                    <ReactQuill value={editorState.text}
                                placeholder="Enter the survey title here"
                                onChange={handleSTitle}/>
                </div>
                <div className="sur-info-div">
                    <ReactQuill value={descriptionState.text}
                                placeholder="Enter description here"
                                onChange={handleSDescription}/>
                </div>

                {/*<div className="logo-row">*/}
                {/*    <button onClick={handleLeftLogo} className="btn btn-secondary btn-sm"><Add className="add"/>Left Logo</button>*/}
                {/*    <button onClick={handleRightLogo} className="btn btn-secondary btn-sm"><Add className="add"/>Right Logo</button>*/}

                {/*</div>*/}

                {/*<div className="view-img-row">*/}
                {/*    {leftImage !== "" ? <img alt="leftImage" className="lft-img" src={`data:image/jpeg;base64,${leftImage}`}/> : ""}*/}
                {/*    {rightImage !== "" ? <img alt="rightImage" className="rgt-img" src={`data:image/jpeg;base64,${rightImage}`}/> : ""}*/}
                {/*</div>*/}

                {
                    <DndProvider backend={HTML5Backend}>
                        <div>
                            {
                                cards.map((question, index) => renderCard(question, index))
                            }
                        </div>

                    </DndProvider>

                }

                {!newQuestionView ? <div className="card add-ques-card">
                    <div className="card-body">
                        <button onClick={showNewQuestionView} className="btn btn-secondary" disabled={!status}>Add Question</button>
                    </div>
                </div> : ''
                }

                {newQuestionView ?
                    <CreateQuestion addQuestions={addNewQuestions} closeView={removeNewQuestionView}
                                    id={newQuestionId} questionType={TEXT_AREA}/> : ''}

                {editQuestionView ?
                    <QuestionEditDialog open={editQuestionView} closeView={() => setEditQuestionView(false)}
                                        id={newQuestionId} questionType={TEXT_AREA}/>: ''}



            </CardContent>



            <SaveSurveyDialog open={openSaveDialog} sId={survey.surveyId}/>

            {openLeftLogo? <LeftLogo open={openLeftLogo} lImage={setLeftImage}  handleClose={() => {
                setOpenLeftLogo(false);
            }} /> : ''}

            {openRightLogo? <RightLogo open={openRightLogo} rImage={setRightImage}  handleClose={() => {
                setOpenRightLogo(false);
            }} /> : ''}


        </div>


    );


}
export default AddQuestion
