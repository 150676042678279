import React from 'react';
import {MenuItem, Select} from '@material-ui/core';


const SimpleSelect = ({data, value, onChange}) => {
    return (
        <Select
            labelId="select-label"
            id="select"
            fullWidth
            value={value || ''}
            onChange={e => onChange(e.target.value)}
        >
            {data.map((e => (<MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)))}
        </Select>
    )
};

export default SimpleSelect;
