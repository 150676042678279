import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import {Edit, QuestionAnswer, Delete, Add, AssignmentInd, Archive, Unarchive} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
  archiveTemplate,
  clearSurvey,
  copyTemplate,
  deleteTemplate,
  loadSurvey,
  loadTemplates
} from "../../actions/SurveyActions";
import {FormControl, InputLabel, NativeSelect} from "@material-ui/core";
import {getAllUserGroups} from "../../actions/ProductActions";


const SurveyList =() =>{
  const history = useHistory();
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState({surveyList:[],archiveSurvey:0});
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(loadTemplates('A', setTemplates))
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllUserGroups(setData));
  }, [dispatch]);

  const editSurvey = (templateId, addQuesStatus) => {
    dispatch(loadSurvey(templateId, () => {
      history.push(`/createSurvey?status=${addQuesStatus}`)
    }));

  }

  const assignGroup = (templateId) => {
    dispatch(loadSurvey(templateId, () => {
      history.push(`/assignSurvey?templateId=${templateId}`)
    }));

  }

  const handleDelete = (templateId) => {
    dispatch(deleteTemplate(templateId, () => {
      history.push(`/surveys`);
      window.location.reload();
    }));
  };

  const handleArchive = (templateId, status) => {
    dispatch(archiveTemplate(templateId, status,() => {
      history.push(`/surveys`);
      window.location.reload();
    }));
  };

  const handleCreateSurvey = () => {
    dispatch(clearSurvey());
    history.push(`/createSurvey?status=true`);
  }

  const handleChangeStatus = (val) => {
    dispatch(loadTemplates(val, setTemplates));
  }

  return(
      <div>

        <div className="survey-list-container container">
          <Card className="survey-list-card p-4">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="survey-list-card-header">RECENT SURVEYS</h2>
              </div>
            </div>
            <CardContent>

              <div className="col-lg-3 active-dropDown">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Survey Status
                  </InputLabel>
                  <NativeSelect defaultValue={"A"} onChange={e => handleChangeStatus(e.target.value)}>
                    <option value={"A"}>Active</option>
                    <option value={"D"}>Archive</option>
                  </NativeSelect>
                </FormControl>
              </div>

              {templates.surveyList && templates.surveyList.map((template, index) => (
                  <div className="row survey-item" key={index}>
                    <div className="col-lg-7 survey-item-name"
                         style={{cursor: 'pointer'}}
                         onClick={() => window.open("/public/survey/template?templateId="+template.id)}>
                      <a href=''><h5>{template.code}</h5></a>
                      <h6>Created Date: {template.createdDatetime}</h6>
                    </div>
                    <div className="col-lg-2 text-center survey-qus-count">
                      <h4>{(template.questionCount=== 0 || template.questionCount=== null) ? 0 : template.questionCount}</h4>
                      <h6>Questions</h6>
                    </div>
                    <div className="col-lg-3 survey-btn-area">
                      <IconButton disabled={!template.editable} onClick={()=> editSurvey(template.id, template.addQuestion)} aria-label="settings">
                        <Edit className="edit" />
                      </IconButton>

                      <IconButton disabled={data.length ===0 } onClick={()=> assignGroup(template.id)} aria-label="settings">
                        <AssignmentInd className="edit" />
                      </IconButton>
                      <IconButton onClick={() => {
                        dispatch(copyTemplate({id: template.id}, () => dispatch(loadTemplates("A"))))
                      }} aria-label="settings">
                        <QuestionAnswer className="question-answer"/>
                      </IconButton>
                      {template.status === 'A' ?
                          <IconButton onClick={() =>  handleArchive(template.id, "D")} aria-label="settings">
                            <Archive className="archive"/>
                          </IconButton> :
                          <IconButton onClick={() =>  handleArchive(template.id, "A")} aria-label="settings">
                            <Unarchive className="unarchive"/>
                          </IconButton>
                      }

                      <IconButton onClick={() =>  handleDelete(template.id)} aria-label="settings">
                        <Delete className="delete"/>
                      </IconButton>
                    </div>
                  </div>

              ))}

            </CardContent>

            <div className="row">
              <div className="col-lg-12 btn-create">
                <button className="btn btn-primary" onClick={handleCreateSurvey}><Add className="add" /> Create</button>
              </div>
            </div>
          </Card>
        </div>

      </div>
  );
}
export default SurveyList;