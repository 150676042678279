import React, {useState} from 'react';
import {Box, Typography} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

const labels = {
    1: 'Useless',
    2: 'Useless+',
    3: 'Poor',
    4: 'Poor+',
    5: 'Ok',
    6: 'Ok+',
    7: 'Good',
    8: 'Good+',
    9: 'Excellent',
    10: 'Excellent+',
};

const StarRating = ({onChange, label, size, value = 0}) => {
        const [hover, setHover] = useState(0);
        return (
            <Box mb={3} borderColor="transparent">
                <Typography component="legend">{label}</Typography>
                <div style={{display: 'flex'}}>
                    <Rating name="10-stars"
                            value={value}
                            size={size}
                            max={10}
                            onChange={(event, newValue) => {
                                onChange(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                    />
                </div>
                {value !== null && <div>{labels[hover !== -1 ? hover : value]}</div>}
            </Box>
        );
    }
;

export default StarRating;