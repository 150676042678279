import React, {useState} from 'react';
import {Card, CardActionArea} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {useDispatch} from "react-redux";
import {saveTemplate, loadTemplates} from "../../actions/SurveyActions";
import EditTemplateCard from "./EditTemplateCard";


const AddTemplateCard = () => {
    const [editing, setEditing] = useState(false);
    const dispatch = useDispatch();


    const handleSave = (data) => {
        setEditing(false);
        dispatch(saveTemplate(data, () => dispatch(loadTemplates())));
    };

    const addCard = () => {
        return <Card>
            <CardActionArea style={{height: 200}} onClick={() => setEditing(true)}>
                <div align="center">
                    <AddIcon/>
                </div>
            </CardActionArea>
        </Card>;
    };


    return <>{editing ? <EditTemplateCard onSave={handleSave} onCancel={() => setEditing(false)}/> : addCard()}</>;
};

export default AddTemplateCard;
