import React, {useState} from 'react';
import parse from "html-react-parser";
import {
    FILE_UPLOAD,
    MULTIPLE_CHOICE,
    PREFERENCE_PRIORITY,
    SCALE_TYPE,
    STAR_RATING,
    TEXT_AREA, 
    YES_NO,
    YES_NO_COMMENT
} from "../createSurvey/AnswerTypes/AnswerTypes";
import {TextAreaType} from "../createSurvey/AnswerTypes/TextAreaType";
import {MultipleChoice} from "../createSurvey/AnswerTypes/MultipleChoice";
import StarRatingType from "../createSurvey/AnswerTypes/StarRatingType";
import ScaleType from "../createSurvey/AnswerTypes/ScaleType";
import {useDispatch} from "react-redux";
import {useQuery} from "../../hooks/useQuery";
import {saveUserSurveyResponses} from "../../actions/SurveyActions";
import {useHistory} from "react-router-dom";
import PreferencePriority from "../createSurvey/AnswerTypes/PreferencePriority";
import {YesNo} from "../createSurvey/AnswerTypes/YesNo";
import FileUpload from "../createSurvey/AnswerTypes/FileUpload";
import { YesNoComment } from '../createSurvey/AnswerTypes/YesNoComment';


//----Answer Type Generate
function generateComponent(questionType, options, questionNo, questionTitle, setData) {
    switch (questionType) {
        case TEXT_AREA:
            return <TextAreaType onChange={(value) => setData({question: questionTitle, answer: value})}/>;
        case MULTIPLE_CHOICE:
            return <MultipleChoice updateOptions={null} isPreview={true} questionOptions={options}
                                   questionId={questionNo}
                                   onChange={(value) => setData({question: questionTitle, answer: value})}/>;
        case STAR_RATING:
            return <StarRatingType questionId={questionNo} onChange={(value) => setData({question: questionTitle, answer: value})}/>;
        case SCALE_TYPE:
            return <ScaleType onChange={(value) => setData({question: questionTitle, answer: value})}/>;
        case PREFERENCE_PRIORITY:
            return <PreferencePriority updateOptions={null} isPreview={true} questionOptions={options} questionId={questionNo}
                                       onChange={(value) => setData({question: questionTitle, answer: value})}/>;
        case YES_NO:
            return <YesNo onChange={(value) => setData({question: questionTitle, answer: value})}/>;
        case YES_NO_COMMENT:
            return <YesNoComment onChange={(value) => setData({question: questionTitle, answer: value})}/>;
        case FILE_UPLOAD:
            return <FileUpload onChange={(value) => setData({question: questionTitle, answer: value})}/>;
        default:
            return <TextAreaType onChange={(value) => setData({question: questionTitle, answer: value})}/>;
    }
}

const RenderSurvey = ({survey, questions}) => {
    const dispatch = useDispatch();
    const query = useQuery();
    const history = useHistory();
    const surveyId = query.get("surveyId");
    const [data, setData] = useState([]);

    const handleSubmit = () => {
        if (surveyId !== null) {
            if(data.length === questions.length){
                dispatch(saveUserSurveyResponses({
                    userSurveyId: surveyId,
                    response: JSON.stringify({answers: data})
                }, (event) => {
                    history.push("/public/survey/template?submit="+true);

                }));

            }else{
                alert('All fields are mandatory');
            }

        }else{
            alert('This view is only for demonstration purposes.')
        }
    }

    const addQuestionAnswers = (answer) => {
        let questions = [...data];
        let question = questions.find(u => u.question === answer.question);
        if (question) {
            question.answer = answer.answer;
        } else {
            questions.push(answer);
        }

        setData(questions);
    }


    return (
        <div className="render-survey-container">
            <div className="card">


                <div className="card-header card-title text-center">
                    <div className="left-logo-view">
                        {/*{survey && survey.leftLogo ? <img alt="lftImage" className="lft-img"*/}
                        {/*                                  src={`data:image/jpeg;base64,${survey.leftLogo}`}/> : ''}*/}
                        <img alt="Logo" src="/images/complexica-logo.png" height="15px"/>
                    </div>

                    <h2 className="survey-title">{survey ? parse(survey.surveyTitle) : ''}</h2>

                    {/*<div className="right-logo-view">*/}
                    {/*    {survey && survey.rightLogo ? <img alt="rightImage" className="rgt-img"*/}
                    {/*                                       src={`data:image/jpeg;base64,${survey.rightLogo}`}/> : ''}*/}
                    {/*</div>*/}
                </div>

                <div className="s-description">{survey && survey.surveyDescription ? parse(survey.surveyDescription) : ''}</div>

                <div className="card-body">
                    {questions ? questions.map((question, index) => (

                        <div className="card q-card" key={index}>
                            <div className="card-body">
                                <div
                                    className="card-text inline-text">{index + 1} . &nbsp; {parse(question.title)}</div>
                                <div className="q-body">
                                    {question.type === MULTIPLE_CHOICE || question.type === PREFERENCE_PRIORITY ?
                                        generateComponent(question.type, question.answers, index + 1,
                                            question.title, (part) => {
                                                addQuestionAnswers(part);
                                            }) :
                                        generateComponent(question.type, [], index + 1,
                                            question.title, (part) => {
                                                addQuestionAnswers(part)
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    )) : ''}

                    <div className="sr-btn-div">
                        <button type="button" onClick={handleSubmit} className="btn btn-primary">Submit Responses
                        </button>
                    </div>

                </div>

                <div className="card-footer text-muted">Copyrights @ COMPLEXICA</div>
            </div>
        </div>


    );
};

export default RenderSurvey;