import React from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {AppBar, Tab, Tabs, Toolbar} from '@material-ui/core';
import UserMenu from "../UserMenu";


const MainMenu = ({menuIndex}) => {
    const history = useHistory();
    return (
        <AppBar>
            <Toolbar style={{backgroundColor: "#c9d0d1"}}>
                <RouterLink to="/">
                    <img
                        alt="Logo"
                        src="/images/complexica-logo.png"
                        height="20"
                    />
                </RouterLink>
                <Tabs
                    value={menuIndex}
                    centered
                    scrollButtons="auto"
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    {/*<Tab label="Products" onClick={() => history.push('/all-products')}/>*/}
                    {/*<Tab label="Templates" onClick={() => history.push('/templates')}/>*/}
                    <Tab label="Surveys" onClick={() => history.push('/surveys')}/>
                    <Tab label="Respondents" onClick={() => history.push('/respondents')}/>
                </Tabs>
                <span style={{flex: '1 1 auto'}}/>
                <UserMenu/>
            </Toolbar>
        </AppBar>
    );
};

export default MainMenu;
