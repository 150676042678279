import React, {useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux'
import {loadTemplates} from "../../actions/SurveyActions";
import TemplateCard from "./TemplateCard";
import AddTemplateCard from "./AddTemplateCard";

const TemplateList = () => {
    const dispatch = useDispatch();
    const templates = useSelector(state => state.templates);
    useEffect(() => {
        dispatch(loadTemplates())
    }, [dispatch]);
    return (
        <Grid container spacing={3} style={{paddingTop: 10}}>
            {templates && templates.map(template => (
                <Grid item key={template.id} lg={6} md={6} xs={12}>
                    <TemplateCard template={template}/>
                </Grid>))}
            <Grid item lg={6} md={6} xs={12}>
                <AddTemplateCard/>
            </Grid>
        </Grid>
    )
};

export default TemplateList;
