import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {useDispatch} from "react-redux";
import moment from "moment/moment";
import {changeSurveyStatus, loadProductSurveys} from "../../../actions/ProductActions";
import ProductSurveyDetails from "./ProductSurveyDetails";

const ProductSurveyTable = ({productId, reload, openModal}) => {
    const [expand, setExpand] = useState(null);
    const [surveys, setSurveys] = useState([]);
    const [reloadB, setReloadB] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadProductSurveys({productId}, (data) => {
            setSurveys(data.reverse());
        }));
    }, [dispatch, productId, reload, reloadB]);
    let headerStyle = {backgroundColor: 'aliceblue'};

    const handleStatus = (id, val) => {
        dispatch(changeSurveyStatus({id: id, assignStatus: val }, ()=>{
            dispatch(loadProductSurveys({productId}, (data) => {
                setSurveys(data.reverse());
            }));
        }));

    }

    const handleEdit = (productSurvey) => {
        openModal(productSurvey);
    }

    const expandRow = (s) => {
        if(s.completedUsers > 0) {
            setExpand(s.id === expand ? null : s.id)
        }
    }

    return (<Table size="small">
            <TableHead>
                <TableRow>
                    {/*<TableCell style={headerStyle}>Name</TableCell>*/}
                    <TableCell style={headerStyle}>Survey Name</TableCell>
                    <TableCell style={headerStyle}>Start Date</TableCell>
                    <TableCell style={headerStyle}>End Date</TableCell>
                    <TableCell style={headerStyle}>Users Completed</TableCell>
                    <TableCell style={headerStyle}>Users Assigned</TableCell>
                    <TableCell style={headerStyle}>Status</TableCell>
                    <TableCell style={headerStyle}>Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {surveys.map(s => (
                    <React.Fragment key={s.id}>
                        <TableRow key={s.id} hover={true} style={{cursor: 'pointer'}}
                                  onClick={() => expandRow(s)}>
                            {/*<TableCell>{s.name}</TableCell>*/}
                            <TableCell>{s.templateName}</TableCell>
                            <TableCell>{moment(s.startDate).format('DD-MM-YYYY')}</TableCell>
                            <TableCell>{moment(s.endDate).format('DD-MM-YYYY')}</TableCell>
                            <TableCell>{s.completedUsers}</TableCell>
                            <TableCell>{s.assignedUsers}</TableCell>
                            <TableCell>{s.status}</TableCell>
                            <TableCell>{s.status ==='Finished' ? '' : s.status ==='In Progress' ?
                                <button className="btn btn-info" onClick={()=> handleStatus(s.id, 'H')}>Hold</button> :
                                <div className="publish-btn-group">
                                    <button className="btn btn-success" onClick={()=> handleStatus(s.id, 'P')}>Publish</button>
                                    <button className="btn btn-edit-sDetails" onClick={()=> handleEdit(s)}>Edit</button>
                                </div>
                                 }
                            </TableCell>
                        </TableRow>
                        {s.id === expand && <TableRow key={"expanded-" + s.id}>
                            <TableCell colSpan={7}>
                                <ProductSurveyDetails productSurveyId={s.id} onReload={setReloadB}/>
                            </TableCell> 
                        </TableRow>
                        }
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    )
};

export default ProductSurveyTable;
