import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Container} from '@material-ui/core';
import SurveyMenu from "./SurveyLayoutMenu";

const useStyles = makeStyles({
  root: {
    paddingTop: 56,
    height: '100%',
  },
  content: {
    height: '100%'
  }
});

const SurveyLayout = props => {
  const {children, menuIndex} = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SurveyMenu menuIndex={menuIndex}/>
      <Container>
        <div className={classes.content}>
          {children}
        </div>
      </Container>
    </div>
  )
};

SurveyLayout.propTypes = {
  children: PropTypes.node
};

export default SurveyLayout;
