import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const SimplePropertyConfig = ({component, onChange}) => {
    return (<div style={{width: 400}}>
            <TextField label="Title" value={component.title || ""} fullWidth
                       onChange={e => onChange({...component, title: e.target.value})}/>
            <TextField label="Label" value={component.label || ""} fullWidth
                       onChange={e => onChange({...component, label: e.target.value})}/>
            <FormControl fullWidth>
                <InputLabel>Size</InputLabel>
                <Select value={component.size || "medium"}
                        onChange={e => onChange({...component, size: e.target.value})}>
                    <MenuItem value='small'> Small</MenuItem>
                    <MenuItem value='medium'> Medium</MenuItem>
                    <MenuItem value='large'> Large</MenuItem>
                </Select>
            </FormControl>
            <TextField label="Property Name" value={component.propertyName || ""} fullWidth
                       onChange={e => onChange({...component, propertyName: e.target.value})}/>
        </div>
    );
};

export default SimplePropertyConfig;