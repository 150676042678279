import React from 'react';
import {DropzoneArea} from "material-ui-dropzone";
import Typography from "@material-ui/core/Typography";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    overrides: {
        MuiDropzoneArea: {
            root: {
                minHeight: 10,
            },
            text: {
                marginTop: 5,
                marginBottom: 0
            },
            icon: {
                color: 'rgba(0, 0, 0, 0.87)',
                width: 35,
                height: 35
            }
        },
        MuiGrid: {
            container: {
                width: 100,
                display: "flex",
                flexWrap: "wrap",
                boxSizing: "border-box",
                justifyContent: "center"
            },
            item: {
                paddingTop: 30,
                paddingBottom: 30
            }
        },
        MuiDropZonePreviewList: {
            removeButton: {
                top: 25,
                right: -13,
                width: 40,
                height: 40,
                opacity: 0,
                position: "absolute",
                transition: ".5s ease"
            }
        },
        MuiTypography: {
            root: {
                margin: 0,
                display: "flex",
                justifyContent: "center"
            }
        }
    }
});

const DropzoneComponent = ({onChange, label}) => {
    return (
        <div className="container"
             style={{
                 display: "flex",
                 flexDirection: "column",
                 alignContent: "center",
                 justifyContent: "center",
                 alignItems: "center"
             }}>
            <div className="file-upload">
                <Typography>
                    { label }
                </Typography>
            </div>
            <MuiThemeProvider theme={theme}>
                <DropzoneArea
                    filesLimit={1}
                    showFileNames
                    showFileNamesInPreview
                    showAlerts={false}
                    maxFileSize={50000000}
                    dropzoneText={null}
                    dropzoneClass={"drop-zone-area"}
                    onChange={onChange.bind(this)}
                    styles={{ minHeight: 10 }}
                />
            </MuiThemeProvider>
        </div>
    );
};

export default DropzoneComponent;