import React, {useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from '@material-ui/core';
import {useDispatch} from "react-redux";

import {changeUserGroup, getUserGroups, loadProductUsers} from "../../../actions/ProductActions";
import moment from "moment/moment";
import AddGroupButton from "./AddGroupButton";
import SimpleSelect from "../../../components/SimpleSelect";
import {useQuery} from "../../../hooks/useQuery";

const ProductUsers = () => {
    const [page, setPage] = useState(0);
    const [reload, setReload] = useState(0);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(30);
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const query = useQuery();
    const productId = query.get("productId");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadProductUsers({productId, page, pageSize}, (data) => {
            setUsers(data.items);
            setTotal(data.total)
        }));
    }, [dispatch, productId, page, pageSize, reload]);
    useEffect(() => {
        dispatch(getUserGroups({productId}, setGroups));
    }, [dispatch, productId]);

    const handleUserGroupChange = (userId, groupId) => {
        dispatch(changeUserGroup({userId, groupId}, () => setReload(Math.random())))
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
    };
    let headerStyle = {backgroundColor: 'aliceblue'};
    return (
        <Paper>
            <AddGroupButton productId={productId} handleNew={(newGroup) => setGroups([...groups, newGroup])}/>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={headerStyle}>First Name</TableCell>
                        <TableCell style={headerStyle}>Last Name</TableCell>
                        <TableCell style={headerStyle}>Email</TableCell>
                        <TableCell style={{...headerStyle, width: 150}}>Group</TableCell>
                        <TableCell style={headerStyle}>Last Activity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell>{user.firstName}</TableCell>
                            <TableCell>{user.lastName}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell><SimpleSelect data={groups} value={user.groupId}
                                                     onChange={(groupId) => handleUserGroupChange(user.id, groupId)}
                                                     productId={productId}/>
                            </TableCell>
                            <TableCell>{moment(user.lastActivity).format('DD-MM-YYYY hh:mm a')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
};

export default ProductUsers;
