import React, {useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from '@material-ui/core';
import {useDispatch} from "react-redux";
import {loadProductActivities} from "../../actions/ProductActions";
import moment from "moment/moment";
import {useQuery} from "../../hooks/useQuery";

const ProductActivities = () => {
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [activities, setActivities] = useState([]);
    const query = useQuery();
    const productId = query.get("productId");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadProductActivities({productId, page, pageSize}, (data) => {
            setActivities(data.items);
            setTotal(data.total)
        }));
    }, [dispatch, productId, page, pageSize]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
    };
    let headerStyle = {backgroundColor: 'aliceblue'};
    return (
        <Paper>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={headerStyle}>User Full Name</TableCell>
                        <TableCell style={headerStyle}>Activity Type</TableCell>
                        <TableCell style={headerStyle}>Activity Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activities.map((activity, idx) => (
                        <TableRow key={idx}>
                            <TableCell>{activity.user}</TableCell>
                            <TableCell>{activity.type}</TableCell>
                            <TableCell>{moment(activity.date).format('DD-MM-YYYY hh:mm a')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
};

export default ProductActivities;
