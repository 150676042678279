import React from 'react';
import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import ReduxPromise from 'redux-promise'
import ReduxThunk from 'redux-thunk';

import App from './App';
import reducers from './reducers';
import ApiMiddleware from "./ApiMiddleware";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(ReduxPromise, ApiMiddleware, ReduxThunk))
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    // document.querySelector('#root')
);