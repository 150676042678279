import {ALERT_CLOSE, ALERT_INFO, API_ERROR} from "../actions/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {isShow: false, message:null, type:'info'}, action) => {
    switch (action.type) {
        case API_ERROR:
            return action.error;
        case ALERT_INFO:
            return  action.payload;
        case ALERT_CLOSE:
            return {isShow: false, message:null};
        default:
            return state;
    }
}
export const alertInfo = message => ({
    type: ALERT_INFO,
    payload: {isShow: true, message:message, type:'info'}
});
export const alertClose = () => ({
    type: ALERT_CLOSE
});
export const alertError = message => ({
    type: ALERT_INFO,
    payload: {isShow: true, message:message, type:'error'}
});