import React, {useState} from 'react';
import {Card, CardActionArea} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditProductCard from "./EditProductCard";


const AddProductCard = () => {
    const [editing, setEditing] = useState(false);

    const addCart = () => {
        return <Card>
            <CardActionArea style={{height: 357}} onClick={() => setEditing(true)}>
                <div align="center">
                    <AddIcon/>
                </div>
            </CardActionArea>
        </Card>;
    };

    const editCart = () => {
        return <EditProductCard onCancel={() => setEditing(false)}/>;
    };

    return <>{editing ? editCart() : addCart()}</>;
};

export default AddProductCard;
